import DashboardModules from 'modules/dashboard';

const Dashboard = () => {

  return (
    <div className='pt-4 pb-20'>
      <div className='px-4 sm:px-6 lg:px-8'><DashboardModules /></div>
    </div>
  )
};

export default Dashboard;
