// import Table from "components/shared/Table";
import { TestTable } from "components/test";

const Test = () => {
  return (
    <div>
      <TestTable />
    </div>
  );
};

export default Test;