import { Form, Modal, notification } from "antd";
import Input from "components/shared/Input";
import { yupResolver } from '@hookform/resolvers/yup';
import { completeFormValidationSchema } from "utils/validationSchemas/authSchema";
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'components/shared/Button';
import { useNavigate } from "react-router-dom";
import AuthLayout from "components/layouts/AuthLayout";
import { CompleteRegisterFormPayload } from "types/auth-types";
import { completeApi } from "services/api/authApi";
import { useMutation } from '@tanstack/react-query';
import { unAuthenticatedErrorHandler } from 'services/axios/errorHandler';
import { useState, useEffect } from "react";
import Mark from 'assets/yellow-mark.svg';

const CompleteRegisterAuth = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const { handleSubmit, control, formState } = useForm<CompleteRegisterFormPayload>({
    resolver: yupResolver(completeFormValidationSchema),
  });

  const onSubmit: SubmitHandler<CompleteRegisterFormPayload> = (data) => {
    console.log(data, "data");
    handleRegister(data);
  };

  const { mutate: handleRegister, isPending: isLoading } = useMutation({
    mutationFn: completeApi,
    onSuccess: () => {
      navigate('/dashboard');
      notification.success({ message: "Registration successfully. Provide your credentials to Login" });
    },
    onError: (error) => {
      const err = unAuthenticatedErrorHandler(error);
      console.log(error, "error");
      notification.error({ message: error.message });
      if (err.toLowerCase().includes('verified')) {
        navigate('/register/verify-email');
      }
    },
  });

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <AuthLayout>
      <>
        <div className="flex flex-col gap-3">
          <div className="mt-32 text-left">
            <h1 className="text-4xl text-dark mb-2 font-medium">Let’s get to know you</h1>
            <p className="text-lg text-dark/80 font-light mb-4">The information you provide is exclusively used to match you with relevant testing opportunities.</p>
          </div>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="mb-auto"
          >
            <Input
              fullWidth
              name="first_name"
              label="First Name"
              placeholder="Temitope"
              formState={formState}
              control={control}
            />
            <Input
              fullWidth
              name="last_name"
              label="Last Name"
              placeholder="Aiyegbusi"
              formState={formState}
              control={control}
            />
            <Input
              fullWidth
              name="username"
              label="Username"
              placeholder="stephaniebaby001"
              formState={formState}
              control={control}
            />
            <div className="mt-4 mb-9 flex flex-row justify-between">
              <p>Your username safeguards your identity from our clients.</p>
            </div>
            <Button type="submit" state='primary' label="Continue" isLoading={isLoading} />
          </Form>
        </div>
        <Modal
          title={false}
          open={isModalVisible}
          onCancel={handleModalCancel}
          closeIcon={false}
          footer={null}
          width={450}
        >
          <img src={Mark} alt="Mark" className="mt-6" />
          <h1 className='mt-5 mb-6 text-[25px] -tracking-[0.04em]'>Congratulations, your email has been verified.</h1>
          <Button onClick={handleModalCancel} label='Continue Registration' />
        </Modal>
      </>
    </AuthLayout>
  );
}

export default CompleteRegisterAuth;
