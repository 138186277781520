import { useAuth } from 'hooks';
import { Form, notification, Spin } from 'antd';
import 'components/shared/inputContainer.scss';
import { Button } from "components/shared/Button";
import { useEffect, useMemo, useState } from "react";
import { ProfileFormPayload } from 'types/user-types';
import { useForm, Controller } from 'react-hook-form';
import CustomCheckboxGroup from 'components/shared/checkBox';
import RadioButtonGroup from "components/shared/radioButton";
import InputContainer from "components/shared/inputContainer";
import { useGetCountries, useGetStatesApi, useGetCitiesApi } from 'services/api/locationApi';
import { createProfileApi, updateProfileApi, useGetIndustriespi, useProfileApi } from 'services/api/userApi';
import { checkboxOptionsA, checkboxOptionsC, checkboxOptionsD, checkboxOptionsF, selectOptionsA, selectOptionsB, selectOptionsD, radioOptionsA, checkboxOptionsE, checkboxOptionsB } from "./values";

interface Industry {
  id: string;
  name: string;
}

interface Country {
  iso2: any;
  id: string;
  name: string;
  emoji: string;
}

interface State {
  iso2: any;
  id: string;
  name: string;
}
interface City {
  id: string;
  name: string;
}

const CompleteProfile = () => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  // const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [profileId, setProfileId] = useState<string | null>(null);


  const { first_name, last_name, email } = user || {};

  const { watch, register, control, formState: { errors }, handleSubmit, reset, setValue } = useForm<ProfileFormPayload>({
    defaultValues: {
      user: user.id,
      full_name: `${first_name || ''} ${last_name || ''}`,
      email: email || '',
      gender: '',
      age: 0,
      country: '',
      language: '',
      state: '',
      city: '',
      internet_type: [],
      operating_system: [],
      tablet: '',
      smart_phone: '',
      primary_device: [],
      frequency_of_internet_used: '',
      job_title: '',
      industry: null,
      accessibility_requirements: [],
      types_of_online_activities: [],
    },
  });

  const countryId = watch('country');
  const stateId = watch('state');

  const { data: industries, isLoading: industryLoading } = useGetIndustriespi();
  const { data: countries, isLoading: countryLoading } = useGetCountries();

  const { data: profileData, isLoading: profileLoading } = useProfileApi();


  useEffect(() => {
    if (profileData) {
      setProfileId(profileData.id);
      reset({
        ...profileData,
        user: user?.id,
        full_name: profileData.full_name || `${first_name || ''} ${last_name || ''}`,
        email: profileData.email || email,
        gender: profileData.gender || '',
        age: profileData.age || 0,
        country: profileData.country || '',
        language: profileData.language || '',
        state: profileData.state || '',
        city: profileData.city || '',
        internet_type: profileData.internet_type || [],
        operating_system: profileData.operating_system || [],
        tablet: profileData.tablet || '',
        smart_phone: profileData.smart_phone || '',
        primary_device: profileData.primary_device || [],
        frequency_of_internet_used: profileData.frequency_of_internet_used || '',
        job_title: profileData.job_title || '',
        industry: profileData.industry || '',
        accessibility_requirements: profileData.accessibility_requirements || [],
        types_of_online_activities: profileData.types_of_online_activities || [],
      });
    }
  }, [profileData, reset, user, first_name, last_name, email]);

  const { states, isGettingStates } = useGetStatesApi({ countryId });
  const { cities, isGettingCities } = useGetCitiesApi({ countryId, stateId });

  const industryOptions = useMemo(() => {
    if (!industries || typeof industries !== 'object') {
      return [];
    }
    return Object.values(industries).map((industry) => ({
      value: (industry as Industry).name,
      label: (industry as Industry).name,
    }));
  }, [industries]);


  const countryOptions = useMemo(() => {
    if (!countries || typeof countries !== 'object') {
      return [];
    }
    return Object.values(countries).map((country) => ({
      value: (country as Country).iso2,
      label: (country as Country).name,
      // label: (country as Country).emoji + (country as Country).name,
    }));
  }, [countries]);

  const stateOptions = useMemo(() => {
    if (!states || typeof states !== 'object') {
      return [];
    }
    return Object.values(states).map((state) => ({
      value: (state as Country).iso2,
      label: (state as State).name,
    }));
  }, [states]);

  const cityOptions = useMemo(() => {
    return cities
      ? cities?.map((city: any) => ({
        value: (city as City).name,
        label: (city as City).name,
      }))
      : [];
  }, [cities]);




  const handleCancel = () => {
    setIsEditing(false);
  };

  const onSubmit = (data: ProfileFormPayload) => {
    handleProfileSubmit(data);
  };

  const handleProfileSubmit = async (data: ProfileFormPayload) => {
    try {
      if (profileId) {
        // Update existing profile
        const response = await updateProfileApi(profileId, data);
        if (response) { // Check if response is successful
          notification.success({ message: 'Profile updated successfully!' });
          setIsEditing(false);
        } else {
          notification.error({ message: 'Failed to update profile. Please try again.' });
        }
      } else {
        // Create new profile
        const response = await createProfileApi(data);

        if (response) {
          notification.success({ message: 'Profile created successfully!' });
          setIsEditing(false);
        } else {
          notification.error({ message: 'Failed to create profile. Please try again.' });
        }
      }
    } catch (error) {
      notification.error({ message: 'An error occurred. Please try again later.' });
    }
  };




  const countryName = useMemo(() => {
    if (countryOptions.length && profileData?.country) {
      const country = countryOptions.find(option => option.value === profileData.country);
      return country ? country.label : '';
    }
    return '';
  }, [countryOptions, profileData]);

  const stateName = useMemo(() => {
    if (stateOptions.length && profileData?.state) {
      const state = stateOptions.find(option => option.value === profileData.state);
      return state ? state.label : '';
    }
    return '';
  }, [stateOptions, profileData]);

  if (profileLoading || countryLoading || isGettingStates) {
    return <div><Spin className='spinner' size="large" /></div>;
  }


  return (
    <div className="mx-auto px-5 md:px-0">
      <div className="rounded-full flex justify-center items-center cursor-pointer flex-col gap-y-2 mb-3">
        <div className=" object-cover w-[100px] h-[100px] rounded-full bg-dark tetx-white flex justify-center items-center text-white font-bold text-4xl uppercase">
          <span>{first_name?.charAt(0)}</span>
          <span>{last_name?.charAt(0)}</span>
        </div>
        <div className="text-center">
          <h3 className="text-dark font-medium text-xl tex">{first_name} {last_name}</h3>
          <p className="text-base font-normal text-[#595959] text-center">
            <div>
              {profileData ? <div><span>{stateName}</span>, <span>{countryName}</span></div> : ""}
            </div>
          </p>
        </div>
      </div>
      <div className="flex justify-center mx-auto">
        {!isEditing && <Button label="Edit Profile" onClick={() => setIsEditing(true)} />}
      </div>
      <div className="mt-16">
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="mb-auto"
        >
          <section className="flex flex-col md:flex-row justify-start items-start gap-y-4">
            <div className=' md:w-1/3'>
              <h6 className='text-lg font-medium'> Personal Information</h6>
              <p className='text-xs text-[#1E1E1E66]'>Take control of your account with <br /> our easy-to-use settings.</p>
            </div>
            <div className='w-full md:w-2/3'>
              <div className='flex w-full flex-col md:flex-row gap-5 mb-5'>
                <InputContainer
                  label="Full Name"
                  name='full_name'
                  type="text"
                  error={errors.full_name?.message}
                  register={register}
                  defaultValue={`${first_name} ${last_name}`}
                  disabled
                />
                <InputContainer
                  label="Email"
                  name='email'
                  type="email"
                  error={errors.email?.message}
                  register={register}
                  defaultValue={`${email}`}
                  disabled
                />
              </div>
              <div className='flex w-full flex-col md:flex-row gap-5 mb-5'>
                <InputContainer
                  label="Gender"
                  name='gender'
                  type="select"
                  // values={}
                  // error={errors.gender?.message}
                  register={register}
                  disabled={!isEditing}
                  options={selectOptionsA}


                />
                <InputContainer
                  label="Age"
                  name='age'
                  type="select"
                  // error={errors.age?.message}
                  placeholder='Select an Option'
                  register={register}
                  disabled={!isEditing}
                  options={selectOptionsB}


                />
              </div>
              <div className='flex w-full flex-col md:flex-row gap-5 mb-5'>
                <InputContainer
                  label="Nationality"
                  name='country'
                  type="select"
                  error={errors.country?.message}
                  register={register}
                  options={countryOptions}
                  loading={countryLoading}

                  disabled={!isEditing}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('country', e.currentTarget.value);
                    setValue('state', profileData?.state);
                    setValue('city', profileData?.city);
                  }}
                />
                <InputContainer
                  label="Languages"
                  name='language'
                  type="select"
                  error={errors.language?.message}
                  register={register}
                  options={selectOptionsD}
                  disabled={!isEditing}

                />
              </div>
              <div className='flex w-full flex-col md:flex-row gap-5 mb-5'>
                <InputContainer
                  label="State"
                  name='state'
                  type="select"
                  error={errors.state?.message}
                  register={register}
                  options={stateOptions}
                  loading={isGettingStates}

                  disabled={!isEditing ?? !countryId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('state', e.currentTarget.value);
                    setValue('city', profileData.city);
                  }}
                />
                <InputContainer
                  placeholder={isGettingCities ? 'Select City' : 'Loading...'}
                  label="City"
                  name='city'

                  type="select"
                  error={errors.city?.message}
                  register={register}
                  options={cityOptions}
                  loading={isGettingCities}
                  disabled={!isEditing ?? !stateId}
                />
                {/* <InputContainer
                  label="City"
                  name='city'
                  type="text"
                  error={errors.city?.message}
                  register={register}
                  disabled={!isEditing}
                  onSelect={(v: any) => setSelectedStateId(v.target.value)}
                /> */}
              </div>
            </div>
          </section>
          <section className='flex flex-col md:flex-row  justify-start items-start mt-9 gap-y-4'>
            <div className='md:w-1/3'>
              <h6 className='text-lg font-medium'>Technology and Devices</h6>
              <p className='text-xs text-[#1E1E1E66]'>Take control of your account with <br /> our easy-to-use settings.</p>
            </div>
            <div className='w-full md:w-2/3'>
              <div className='flex w-full flex-col md:flex-row gap-x-5 mb-5'>
                <div className='md:w-1/2'>
                  <p className=' text-sm md:text-base text-[#1E1E1E66] pb-2'>Type of internet connection</p>
                  <Controller
                    control={control}
                    name="internet_type"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsA} disabled={!isEditing} />
                    )}
                  />
                </div>
                <div className='md:w-1/2'>
                  <p className=' text-sm md:text-base text-[#1E1E1E66] pb-2'>Operating system</p>
                  {/* //checkboxOptionsB */}
                  <Controller
                    control={control}
                    name="operating_system"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsB} disabled={!isEditing} />
                    )}
                  />
                  {/* <Controller
                    control={control}
                    name="operating_system"
                    render={({ field }) => (
                      <RadioButtonGroup
                        {...field}
                        value={field.value ?? ''} // Ensure value is always a string or number
                        options={radioOptionsB}
                        disabled={!isEditing}
                      />
                    )}
                  /> */}
                </div>
              </div>
              <div className='flex w-full flex-col md:flex-row gap-x-5 mb-5'>
                <div className='md:w-1/2'>
                  <p className=' text-sm md:text-base text-[#1E1E1E66] pb-2'>Tablet (optional)</p>
                  <Controller
                    control={control}
                    name="tablet"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsC} disabled={!isEditing} />
                    )}
                  />
                </div>
                <div className='md:w-1/2'>
                  <p className=' text-sm md:text-base text-[#1E1E1E66] pb-2'>Smart Phones (optional)</p>
                  <Controller
                    control={control}
                    name="smart_phone"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsD} disabled={!isEditing} />
                    )}
                  />
                </div>
              </div>


              <div className=' flex gap-x-3 mb-5 md:w-1/2'>
                <div className=''>
                  <p className=' text-sm md:text-base text-[#1E1E1E66] pb-2'>Operating system on primary device</p>
                  <Controller
                    control={control}
                    name="primary_device"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsD} disabled={!isEditing} />
                    )}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </section>
          <section className='flex flex-col md:flex-row  justify-start items-start mt-9 gap-y-4'>
            <div className='md:w-1/3'>
              <h6 className='text-lg font-medium'>Usage and Behaviour</h6>
              <p className='text-xs text-[#1E1E1E66]'>Take control of your account with  <br /> our easy-to-use settings.</p>
            </div>
            <div className='md:w-2/3'>
              <div className='flex mb-8  items-start flex-col gap-y-4 md:flex-row'>
                <div className='w-full md:w-1/2'>
                  <p className='text-base text-[#1E1E1E66] pb-2'>Internet Usage Frequency</p>
                  <Controller
                    control={control}
                    name="frequency_of_internet_used"
                    render={({ field }) => (
                      <RadioButtonGroup
                        {...field}
                        value={field.value ?? ''} // Ensure value is always a string or number
                        options={radioOptionsA}
                        disabled={!isEditing}
                      />
                    )}
                  />

                </div>
                <div className='w-full md:w-1/2'>
                  <p className='text-base text-[#1E1E1E66] pb-2'>Types of online activities</p>
                  <Controller
                    control={control}
                    name="types_of_online_activities"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsE} disabled={!isEditing} />
                    )}
                  />
                </div>
              </div>
            </div>
          </section>
          <section className='flex flex-col md:flex-row  justify-start items-start mt-9 gap-y-4'>
            <div className='md:w-1/3'>
              <h6 className='text-lg font-medium'>Occupation and Industry</h6>
              <p className='text-xs text-[#1E1E1E66]'>Take control of your account with <br /> our easy-to-use settings.</p>
            </div>
            <div className='w-full md:w-2/3'>
              <div className='flex w-full flex-col md:flex-row gap-5 mb-5'>
                <InputContainer
                  label="Job title"
                  name='job_title'
                  type="text"
                  error={errors.job_title?.message}
                  register={register}
                  disabled={!isEditing}
                  placeholder='Enter Job Title'

                />
                <InputContainer
                  label="Industry"
                  name='industry'
                  type="select"
                  error={errors.industry?.message}
                  register={register}
                  options={industryOptions}
                  loading={industryLoading}
                  disabled={!isEditing}

                />
              </div>
            </div>
          </section>
          <section className='flex flex-col md:flex-row  justify-start items-start mt-9 gap-y-4'>
            <div className='md:w-1/3'>
              <h6 className='text-lg font-medium'>Accessibility Requirement</h6>
              <p className='text-xs text-[#1E1E1E66]'>Take control of your account with <br /> our easy-to-use settings.</p>
            </div>
            <div className='w-full md:w-2/3'>
              <div className=' flex gap-x-3 mb-5 md:w-1/2'>
                <div className=''>
                  <p className=' text-sm md:text-base text-[#1E1E1E66] pb-2'>Impairments</p>
                  <Controller
                    control={control}
                    name="accessibility_requirements"
                    render={({ field }) => (
                      <CustomCheckboxGroup {...field} options={checkboxOptionsF} disabled={!isEditing} />
                    )}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </section>
          <div className='flex mt-14 justify-end gap-x-5'>
            {isEditing && (
              <>
                <Button label="Cancel" state="tetiary" onClick={handleCancel} />
                <Button label="Save" state="primary" type='submit' isLoading={profileLoading} />
              </>
            )}
          </div>
        </Form>
      </div>
    </div>
  )
}
export default CompleteProfile