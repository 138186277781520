import eric from 'assets/testimonial/eric.png';
import eden from 'assets/testimonial/eden.png';
import mike from 'assets/testimonial/mike.png';
import mark from 'assets/testimonial/mark.png';
import john from 'assets/testimonial/john.png';

export const testimonials = [
  {
    testimonial:
      "TechhRevolve provided invaluable assistance to GuestsnHosts, our events management platform. Their support in collecting feedback directly from events managers was instrumental in helping us grasp their precise platform requirements. This input proved indispensable in refining our platform to better meet the needs of our users.",
    user: 'Rebecca Adedire',
    company: 'Co-Founder of Guests and Hosts',
    image: eric,

  },
  {
    testimonial:
      "Veet's growth journey was significantly supported by TechRevolve. Their dedicated efforts in collecting feedback from our mentors and mentees were incredibly valuable. By pinpointing areas for improvement, they provided actionable insights that empowered us to enhance our platform and elevate the mentorship experience for all users. We're truly grateful for TechRevolve's expertise and support in helping us realize our mission.",
    user: 'Augustine Asiuhwu',
    company: 'Co-Founder of Veet',
    image: eden,
  },
  {
    testimonial:
      "During my master's studies in the UK, TechRevolve proved to be an invaluable asset. They aided me in gathering feedback from targeted demographics in Nigeria for my dissertation. Their expertise and support were instrumental, allowing me to access crucial insights and successfully complete my research.",
    user: 'Oluwafayobami Aiyegbusi',
    company: 'Master Student',
    image: mike,
  },
  {
    testimonial:
      "TechRevolve played a pivotal role in the launch of our gift card buying and selling platform, AOVentures. Their expertise in user research helped us gather valuable feedback from potential users, enabling us to refine our MVP and confidently go live. Thanks to TechRevolve's support, we are now poised to make waves in the industry. Highly recommended!",
    user: 'Alexis Ojere',
    company: 'CEO of AOVentures',
    image: mark,
  },
  {
    testimonial:
      "TechhRevolve provided invaluable assistance to GuestsnHosts, our events management platform. Their support in collecting feedback directly from events managers was instrumental in helping us grasp their precise platform requirements. This input proved indispensable in refining our platform to better meet the needs of our users.",
    user: 'Rebecca Adedire',
    company: 'Co-Founder of GuestsnHosts',
    image: john,

  },
  {
    testimonial:
      "TechhRevolve provided invaluable assistance to GuestsnHosts, our events management platform. Their support in collecting feedback directly from events managers was instrumental in helping us grasp their precise platform requirements. This input proved indispensable in refining our platform to better meet the needs of our users.",
    user: 'Rebecca Adedire',
    company: 'Co-Founder of GuestsnHosts',
    image: eric,

  },
  {
    testimonial:
      "Veet's growth journey was significantly supported by TechRevolve. Their dedicated efforts in collecting feedback from our mentors and mentees were incredibly valuable. By pinpointing areas for improvement, they provided actionable insights that empowered us to enhance our platform and elevate the mentorship experience for all users. We're truly grateful for TechRevolve's expertise and support in helping us realize our mission.",
    user: 'Augustine Asiuhwu',
    company: 'Co-Founder of Veet',
    image: eden,
  },
  {
    testimonial:
      "During my master's studies in the UK, TechRevolve proved to be an invaluable asset. They aided me in gathering feedback from targeted demographics in Nigeria for my dissertation. Their expertise and support were instrumental, allowing me to access crucial insights and successfully complete my research.",
    user: 'Oluwafayobami Aiyegbusi',
    company: 'Master Student',
    image: mike,
  },
  {
    testimonial:
      "TechhRevolve provided invaluable assistance to GuestsnHosts, our events management platform. Their support in collecting feedback directly from events managers was instrumental in helping us grasp their precise platform requirements. This input proved indispensable in refining our platform to better meet the needs of our users.",
    user: 'Rebecca Adedire',
    company: 'Co-Founder of Guests and Hosts',
    image: john,

  },
  {
    testimonial:
      "TechRevolve played a pivotal role in the launch of our gift card buying and selling platform, AOVentures. Their expertise in user research helped us gather valuable feedback from potential users, enabling us to refine our MVP and confidently go live. Thanks to TechRevolve's support, we are now poised to make waves in the industry. Highly recommended!",
    user: 'Alexis Ojere',
    company: 'CEO of AOVentures',
    image: mark,
  },

];

export const survey = [
  {
    id: 1,
    duration: 5,
    amount: 50,
    heading: "Toople sign up process survey",
    description: "Get user feedback on key account creation, username, password difficulties in our app's sign up process",
    question: "How old are you?",
    option1: "18 to 25",
    option2: "26 to 35",
    option3: "36 to 45",
    option4: "> 46",
    needsEligibility: true
  },
  {
    id: 2,
    duration: 10,
    amount: 50,
    heading: "Toople sign up process survey",
    description: "Get user feedback on key account creation, username, password difficulties in our app's sign up process",
    question: "What type of apps do you typically download?",
    option1: "Games",
    option2: "Social &Communication",
    option3: "Entertainment",
    option4: "Productivity",
    needsEligibility: false
  },
  {
    id: 3,
    duration: 15,
    amount: 50,
    heading: "Toople sign up process survey",
    description: "Get user feedback on key account creation, username, password difficulties in our app's sign up process",
    question: "How would you prefer to create an account?",
    option1: " With my existing social accounts ",
    option2: "By entering my email & creating a password",
    option3: "Just with an email or phone number",
    option4: "No preference",
    needsEligibility: true
  },
  {
    id: 4,
    duration: 10,
    amount: 50,
    heading: "Toople sign up process survey",
    description: "Get user feedback on key account creation, username, password difficulties in our app's sign up process",
    question: "How old are you?",
    option1: "18 to 25",
    option2: "26 to 35",
    option3: "36 to 45",
    option4: "> 46",
    needsEligibility: false
  },
  {
    id: 5,
    duration: 20,
    amount: 50,
    heading: "Toople sign up process",
    description: "Get user feedback on key account creation, username, password difficulties in our app's sign up process",
    question: "How old are you?",
    option1: "18 to 25",
    option2: "26 to 35",
    option3: "36 to 45",
    option4: "> 46",
    needsEligibility: false
  }
];

export const eligibility = [
  {
    id: 1,
    question: "How old are you?",
    option1: "18 to 25",
    option2: "26  to 35",
    option3: "36 to 45",
    option4: "> 46",
  },
  {
    id: 2,
    question: "What type of apps do you typically download?",
    option1: "Games",
    option2: "Social & Communication",
    option3: "Entertainment",
    option4: "Productivity",
  },
  {
    id: 3,
    question: "Which of the images below is clearer?",
    option1: "Entertainment",
    option2: "Productivity",
    option3: "Games",
    option4: "Social & Communication",
  },
  {
    id: 4,
    question: "How would you prefer to create an account?",
    option1: " With my existing social accounts ",
    option2: "By entering my email & creating a password",
    option3: "Just with an email or phone number",
    option4: "No preference"
  }
];

