import React from 'react';
interface SvgIconsProps {
  onClick?: () => void;
  className?: string;
}

export const PlusSvg: React.FC<SvgIconsProps> = ({ onClick, className }) => (
  <svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M19.1667 9.16668H10.8333V0.83332C10.8333 0.373086 10.4602 0 10 0C9.53976 0 9.16668 0.373086 9.16668 0.83332V9.16664H0.83332C0.373086 9.16668 0 9.53977 0 10C0 10.4602 0.373086 10.8333 0.83332 10.8333H9.16664V19.1666C9.16664 19.6269 9.53973 20 9.99996 20C10.4602 20 10.8333 19.6269 10.8333 19.1666V10.8333H19.1666C19.6268 10.8333 19.9999 10.4602 19.9999 10C20 9.53977 19.6269 9.16668 19.1667 9.16668Z" fill="#374957" />
  </svg>
);
export const EyeIcon: React.FC<SvgIconsProps> = ({ onClick, className }) => (
  <svg onClick={onClick} className={`cursor-pointer size-6 ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  </svg>


);
export const EyeSlashIcon: React.FC<SvgIconsProps> = ({ onClick, className }) => (
  <svg onClick={onClick} className={`cursor-pointer size-6 ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
  </svg>



);
export const Close: React.FC<SvgIconsProps> = ({ className }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#F1F1F1" />
    <path d="M30 20C30 14.5 25.5 10 20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20Z" stroke="#4E5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.8299 22.8301L17.1699 17.1701" stroke="#4E5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M22.8299 17.1701L17.1699 22.8301" stroke="#4E5158" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

);
export const Refresh: React.FC<SvgIconsProps> = ({ className }) => (
  <svg className={className} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.75 3.5C12.75 3.08579 13.0858 2.75 13.5 2.75C13.9142 2.75 14.25 3.08579 14.25 3.5V5.9C14.25 6.31421 13.9142 6.65 13.5 6.65H11.1663C10.7521 6.65 10.4163 6.31421 10.4163 5.9C10.4163 5.48579 10.7521 5.15 11.1663 5.15H11.6801C10.8661 4.5805 9.88573 4.25 8.8331 4.25C6.04558 4.25 3.75 6.58042 3.75 9.5C3.75 9.91421 3.41421 10.25 3 10.25C2.58579 10.25 2.25 9.91421 2.25 9.5C2.25 5.79216 5.17755 2.75 8.8331 2.75C10.3029 2.75 11.6574 3.24389 12.75 4.0744V3.5Z" fill="black" />
    <path d="M5.25 15.5C5.25 15.9142 4.91421 16.25 4.5 16.25C4.08579 16.25 3.75 15.9142 3.75 15.5V13.1C3.75 12.6858 4.08579 12.35 4.5 12.35H6.83367C7.24788 12.35 7.58367 12.6858 7.58367 13.1C7.58367 13.5142 7.24788 13.85 6.83367 13.85H6.31991C7.13392 14.4195 8.11427 14.75 9.1669 14.75C11.9544 14.75 14.25 12.4196 14.25 9.5C14.25 9.08579 14.5858 8.75 15 8.75C15.4142 8.75 15.75 9.08579 15.75 9.5C15.75 13.2078 12.8225 16.25 9.1669 16.25C7.69708 16.25 6.34255 15.7561 5.25 14.9256V15.5Z" fill="black" />
  </svg>


);
export const ArrowLeft: React.FC<SvgIconsProps> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 8L4.70711 11.2929C4.31658 11.6834 4.31658 12.3166 4.70711 12.7071L8 16" stroke="#181B01" stroke-width="1.5" stroke-linecap="round" />
    <path d="M5 12L19 12" stroke="#181B01" stroke-width="1.5" stroke-linecap="round" />
  </svg>



);

export const ClockIcon: React.FC<{ color: string }> = ({ color }) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33331 0.625C4.36913 0.625 3.4266 0.910914 2.62491 1.44659C1.82322 1.98226 1.19838 2.74363 0.829402 3.63442C0.460425 4.52521 0.363884 5.50541 0.551987 6.45107C0.74009 7.39672 1.20439 8.26536 1.88617 8.94715C2.56795 9.62893 3.43659 10.0932 4.38225 10.2813C5.32791 10.4694 6.30811 10.3729 7.1989 10.0039C8.08969 9.63494 8.85106 9.01009 9.38673 8.2084C9.9224 7.40671 10.2083 6.46418 10.2083 5.5C10.207 4.20749 9.6929 2.96831 8.77895 2.05436C7.86501 1.14042 6.62583 0.626365 5.33331 0.625ZM7.95831 5.875H5.33331C5.23386 5.875 5.13848 5.83549 5.06815 5.76517C4.99782 5.69484 4.95831 5.59946 4.95831 5.5V2.875C4.95831 2.77554 4.99782 2.68016 5.06815 2.60984C5.13848 2.53951 5.23386 2.5 5.33331 2.5C5.43277 2.5 5.52815 2.53951 5.59848 2.60984C5.66881 2.68016 5.70831 2.77554 5.70831 2.875V5.125H7.95831C8.05777 5.125 8.15315 5.16451 8.22348 5.23484C8.29381 5.30516 8.33331 5.40054 8.33331 5.5C8.33331 5.59946 8.29381 5.69484 8.22348 5.76517C8.15315 5.83549 8.05777 5.875 7.95831 5.875Z" fill={color} />
  </svg>
);



