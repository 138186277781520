const CustomCheckboxGroup = ({ value = [], onChange, options, disabled }: any) => {
  const handleChange = (optionValue: string) => {
    if (value.includes(optionValue)) {
      onChange(value.filter((val: any) => val !== optionValue));
    } else {
      onChange([...value, optionValue]);
    }
  };

  return (
    <div>
      {options.map((option: any) => (
        <label className="flex gap-2 items-center ant-checkbox-input" key={option.value}>
          <input
            type="checkbox"
            value={option.value}
            checked={value.includes(option.value)}
            onChange={() => handleChange(option.value)}
            disabled={disabled}
            className="ant-checkbox-checked"
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default CustomCheckboxGroup;
