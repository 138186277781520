import { Table, Typography } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import Dropdown from "components/shared/Dropdown";
import { Pagination } from "components/shared/Pagination";
import { formatNumber } from "utils";
import { SetStateAction, useState } from "react";
import { DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import "../../styles/_table.scss";
import { testStatuses } from 'types/test-types';
import { TestStatusTag } from 'components/test/TestStatusTag';
import { Modal } from 'antd';
import copy from 'assets/copy.svg'
import { Close } from "assets/svg/SvgIcons";
import 'styles/modal.scss'





function getCards(counts: any) {
  return [
    {
      title: "Total reward",
      value: formatNumber({
        number: counts.totalReward,
        isCurrency: true,
      }),
    },
    {
      title: "Pending",
      value: formatNumber({
        number: counts.pendingReward,
        isCurrency: true,
      }),
    },
    {
      title: "Total Test",
      value: formatNumber({
        number: counts.totalTest,
        isCurrency: false,
      }),
    },
    {
      title: "Total Rejected",
      value: formatNumber({
        number: counts.totalRejected,
        isCurrency: true,
      }),
    },
  ];
}

export const TestTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: 'transactionId',
      key: 'transactionId'
    },
    {
      title: "Date",
      dataIndex: 'createdAt',
      key: 'createdAt'
    },
    {
      title: "Status",
      dataIndex: 'status',
      key: 'status',
      render: (status: testStatuses) => <TestStatusTag status={status} />,
    },
    {
      title: "Amount",
      dataIndex: 'amount',
      key: 'amount'
    },
    {
      title: "",
      align: "center" as "center",
      render: (_: any, data: any) => (
        <Dropdown menu={{ items: getActionItems(data) }}>
          <div
            style={{
              width: "32px",
              height: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 6,
              cursor: "pointer",
              textAlign: "center",
              borderRadius: "8px",
              border: "1px solid #F0F0F0",
              padding: "8px"
            }}
          >
            <p style={{ lineHeight: 0 }}>&bull;</p>
            <p style={{ lineHeight: 0 }}>&bull;</p>
            <p style={{ lineHeight: 0 }}>&bull;</p>
          </div>
        </Dropdown>
      ),
    },
  ];
  function getActionItems(data: any): ItemType[] {
    return [
      {
        key: "view",
        label: "View",
        // onClick: () => data.view(),
        onClick: () => showModal(),
      },
    ];
  }


  const data = [
    {
      transactionId: '123456789ED',
      amount: '$200,000.00',
      createdAt: "Apr 12, 2023 | 09:32AM",
      status: 'success',
      type: 'deposit',
    },
  ];

  const counts = {
    totalReward: 0,
    pendingReward: 0,
    totalTest: 0,
    totalRejected: 0,
  };

  const handlePageClick = (selectedPage: SetStateAction<number>) => {
    setCurrentPage(selectedPage);
  };

  // Duplicate data up to 50 history
  const duplicatedData = Array.from({ length: 19 }, (_, index) => ({
    ...data[0],
    // transactionId: `#${index + 1}`,
  }));

  // Concatenate the existing data with duplicated data
  const dataSource = [...data, ...duplicatedData];

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, dataSource.length);


  // Slice the dataSource to get the data for the current page
  const currentPageData = dataSource.slice(startIndex, endIndex);

  const dateFormat = 'DD/MM/YYYY';

  //Modal 
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [copied, setCopied] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopy = () => {
    const textToCopy = "#45678TR";

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000); // Reset the copied message after 2 seconds
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };



  return (
    <>
      <section className="pt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  gap-4">
          {getCards(counts).map((card, index) => (
            <div key={index} className=" flex flex-col gap-x-[10px] py-[19px] px-8 border-[1px] border-[#D9D9D9] bg-white rounded-[13px]">
              <Typography.Text className="text-sm text-[#1E1E1E66]">{card.title}</Typography.Text>
              <Typography.Text className=" text-[32px] font-semibold text-[#181B01]">{card.value}</Typography.Text>
            </div>
          ))}
        </div>

        <div>
          <div className="mt-[47px] mb-[23px] text-xl flex flex-row">
            <h5>Summary</h5>
            <div className="gap-x-4 flex ml-auto flex-row items-center">
              <DatePicker defaultValue={dayjs('12/09/2023', dateFormat)} format={dateFormat} />
              <Select
                defaultValue="Pending"
                style={{ width: 120 }}
                allowClear
                options={[{ value: 'Pending', label: 'Pending' }]}
              />
            </div>
          </div>
          <Table
            columns={columns}
            // dataSource={currentPageData}
            pagination={false}

          />
          <Modal
            title={false}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            closeIcon={<Close />}
            width={500}

          >
            <div className="border-b border-dashed border-[#595959]/20 mb-3 pb-3 mt-[50px]">
              <div className="mb-6">
                <h1 className="text-dark text-[28px] font-medium">Summary</h1>
                <p className="text-[#595959] font-light text-sm">A summary of your test reward payments are shown below</p>
              </div>
              <h4 className="text-dark text-lg font-normal mb-6">Test details</h4>
              <div className="flex flex-row justify-between mb-4 text-sm items-center font-normal">
                <p className="text-[#595959] ">Test ID</p>
                <p className="flex relative gap-x-2 text-dark ">#45678TR <span onClick={handleCopy}> {copied && <span className=" absolute  text-black text-sm -left-14">Copied!</span>} <img src={copy} alt="Copy Icon" /></span></p>
              </div>
              <div className="flex flex-row justify-between mb-4 text-sm items-center font-normal">
                <p className="text-[#595959] ">Test date and time</p>
                <p className="flex relative gap-x-2 text-dark ">23 Sept, 2023, 3:45pm</p>
              </div>
              <div className="flex flex-row justify-between mb-4 text-sm items-center font-normal">
                <p className="text-[#595959] ">Test status</p>
                <p className="flex relative gap-x-2 text-dark ">Completed</p>
              </div>
            </div>
            <div>
              <h1 className="text-dark text-lg font-normal mb-6">Payment</h1>
              <div className="flex flex-row justify-between mb-4 text-sm items-center font-normal">
                <p className="text-[#595959] ">Amount</p>
                <p className="flex relative gap-x-2 text-dark ">$ 100</p>
              </div>
              <div className="flex flex-row justify-between mb-4 text-sm items-center font-normal">
                <p className="text-[#595959] ">Payment date</p>
                <p className="flex relative gap-x-2 text-dark ">23 September, 2023</p>
              </div>
              <div className="flex flex-row justify-between mb-4 text-sm items-center font-normal">
                <p className="text-[#595959] ">Paypal mail</p>
                <p className="flex relative gap-x-2 text-dark ">johndoe@mail.com</p>
              </div>
              <div className="flex flex-row justify-between mb-6 text-sm items-center font-normal">
                <p className="text-[#595959] ">Payment status</p>
                <p className="flex relative gap-x-2 text-dark ">Paid</p>
              </div>
              <button className="border border-dark rounded-[32px] py-3 px-6">Need support ?</button>
            </div>
          </Modal>
        </div>
        <div className="flex py-1 px-2 md:px-4 items-center text-[#181B01]/60 text-xs md:text-base font-light bg-white">
          {/* Showing results {startIndex + 1} - {endIndex} of {dataSource.length} */}
          <div className="ml-auto">
            {/* <Pagination
              pageCount={Math.ceil(dataSource.length / pageSize)}
              handlePageClick={handlePageClick}
            /> */}
          </div>
        </div>

      </section>
    </>
  );
};

