import { Form, notification } from "antd";
import Input from "components/shared/Input";
import { yupResolver } from '@hookform/resolvers/yup';
import { registerFormValidationSchema } from "utils/validationSchemas/authSchema";
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'components/shared/Button';
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "components/layouts/AuthLayout";
import { RegisterFormPayload } from "types/auth-types";
import { useSignupApi } from "services/api/authApi";
// import { unAuthenticatedErrorHandler } from 'services/axios/errorHandler';





const RegisterAuth = () => {
  const navigate = useNavigate();

  const { handleSubmit, control, formState } = useForm<RegisterFormPayload>(
    {
      resolver: yupResolver(registerFormValidationSchema),
    }
  );


  const onSubmit: SubmitHandler<RegisterFormPayload> = (data) => {
    handleRegister(data);
    localStorage.setItem('email', data.email);
    console.log('email', data.email)
  };

  // const { mutate: handleRegister, isPending: isLoading } = useMutation({
  //   mutationFn: registerApi,
  //   onSuccess: ({ message }) => {
  //     notification.success({ message: message })
  //     navigate('/register/verify-email');
  //   },
  //   onError: (error, message: any) => {
  //     const err = error.message;
  //     console.log(error.message, "error")
  //     notification.error({ message: error.message });
  //   },
  // });

  const onRegisterSuccess = ({ message }: any) => {
    notification.success({ message: message })
    navigate('/register/verify-email');
  };

  const { handleRegister, isLoading } = useSignupApi({ onSuccess: onRegisterSuccess });

  return (
    <AuthLayout>
      <>
        <div className=" flex flex-col gap-3">
          <div className=" mt-32 text-left">
            <h1 className=" text-4xl text-dark mb-2 font-medium">Register</h1>
            <p className=" text-lg text-dark/80 font-light mb-4">Get ready to start testing!</p>
          </div>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="mb-auto"
          >
            <Input
              fullWidth
              name="email"
              label="Email Address"
              placeholder="johntemitope@gmail.com "
              formState={formState}
              control={control}
            />
            <Input
              fullWidth
              name="password"
              label="Password"
              placeholder="**************"
              formState={formState}
              control={control}
              type="password"
              showPasswordIcon={true}
            />
            <div className="mt-4 mb-9 flex flex-row justify-between">
              <Link to='/login' className="text-base font-light text-dark/80 hover:text-dark/50">Already Have an Account? <span className=" font-medium text-[#616B00] hover:text-dark/50">Sign in</span> </Link>
            </div>
            <Button type="submit" state='primary' label="Continue" isLoading={isLoading} />
          </Form>
        </div>
      </>
    </AuthLayout>
  );
};
export default RegisterAuth;
