import { testStatuses } from 'types/test-types';

export const TestStatusTag = ({ status }: { status: testStatuses }) => {
  if (status.toLowerCase().includes('fail'))
    return (
      <div className='w-fit text-[#CA0C0C] font-normal text-sm'>
        <span>Failed</span>
      </div>
    );

  if (status.toLowerCase().includes('success'))
    return (
      <div className='w-fit text-[#008C00] font-normal text-sm'>
        <span>Success</span>
      </div>
    );

  if (status.toLowerCase().includes('pend'))
    return (
      <div className='w-fit text-[#B56300] font-normal text-sm'>

        <span>Pending</span>
      </div>
    );

  return null;
};
