import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { apiRequestParams } from '.';

const API_KEY = 'VzN6VnJCS2pIa2FkMXNkUUFSdXYxR0R0bFBhT0pYWUxBdUM1Z1JoNA==';
const BASE_URL = 'https://api.countrystatecity.in/v1';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-CSCAPI-KEY': API_KEY,
  },
});

export const useGetCountries = () => {
  const fetchCountries = async () => {
    const { data } = await api.get(`countries`);
    return data;
  }
  const { data, isLoading } = useQuery({
    queryKey: ['fetch-countries'],
    queryFn: fetchCountries,
  });

  return { data, isLoading };
}
export const useGetStates = (countryIso2: string) => {
  const fetchStates = async () => {
    const { data } = await api.get(`countries/NG/states`);
    // const { data } = await api.get(`countries/${countryIso2}/states`); 
    return data;
  };

  const { data, isLoading } = useQuery({
    // queryKey: ['fetch-states'],
    queryKey: ['fetch-states', countryIso2],
    queryFn: fetchStates,
    // enabled: !!countryIso2,
  });

  return { data, isLoading };
};

export const useGetStatesApi = ({
  // onSuccess,
  countryId,
}: apiRequestParams & { countryId?: string }) => {
  const { data: states, isFetching: isGettingStates } = useQuery({
    queryKey: ['getStates', countryId],
    queryFn: async () => {
      const { data } = await api.get(`countries/${countryId}/states`);
      return data;
    },
    // onSuccess: onSuccess,
    enabled: !!countryId,
  });
  return {
    states,
    isGettingStates,
    countryId,
  };
};
export const useGetCitiesApi = ({
  onSuccess,
  stateId,
  countryId
}: apiRequestParams & { countryId?: string, stateId?: string }) => {
  const { data: cities, isFetching: isGettingCities } = useQuery({
    queryKey: ['getCities', stateId],
    queryFn: async () => {
      const { data } = await api.get(`/countries/${countryId}/states/${stateId}/cities`);
      return data;
    },
    // onSuccess,
    enabled: !!stateId,
  });
  return {
    cities,
    isGettingCities,
    stateId,
    countryId
  };
};
export const useGetCities = (countryIso2: string, stateIso2: string) => {
  const fetchCities = async () => {
    const { data } = await api.get(`countries/NG/states/LA/cities`, { params: { length: Number.MAX_SAFE_INTEGER } });
    // const { data } = await api.get(`countries/${countryIso2}/states`);
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['fetch-cities'],
    // queryKey: ['fetch-cities', countryIso2],
    queryFn: fetchCities,
    // enabled: !!countryIso2,
  });

  return { data, isLoading };
};