import { Form, notification } from "antd";
import { useAuth } from 'hooks';
import Input from "components/shared/Input";
import { yupResolver } from '@hookform/resolvers/yup';
import { loginFormValidationSchema } from "utils/validationSchemas/authSchema";
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'components/shared/Button';
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "components/layouts/AuthLayout";
import { LoginFormPayload } from "types/auth-types";
import { loginApi } from "services/api/authApi";
import { useMutation } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';

const LoginAuth = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const { handleSubmit, control, formState } = useForm<LoginFormPayload>({
    resolver: yupResolver(loginFormValidationSchema),
  });

  const onSubmit: SubmitHandler<LoginFormPayload> = (data) => {
    handleLogin(data);
  };

  const { mutate: handleLogin, isPending: isLoading } = useMutation({
    mutationFn: loginApi,
    onSuccess: ({ entity, message }) => {
      const token = entity?.access;
      localStorage.setItem('access_token', token);
      localStorage.setItem('techrevolve__user', JSON.stringify(entity?.user))


      // Decode the token
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);
      localStorage.setItem('techrevolve___user', JSON.stringify(decodedToken))
      setUser(entity?.user);

      navigate('/dashboard');
      notification.success({ message: message });
    },
    onError: (message: any, { email }) => {
      // const err = unAuthenticatedErrorHandler(error);
      console.log(message, "error");
      notification.error({ message: message });
      if (message.includes('Verify')) {
        localStorage.setItem('techrevolve_email', email);
        navigate('/register/verify-email');
        localStorage.getItem('techrevolve_email');
      }
    },
  });

  return (
    <AuthLayout>
      <div className="flex flex-col gap-3">
        <div className="mt-32 text-left">
          <h1 className="text-4xl text-dark mb-2 font-medium">Welcome back</h1>
          <p className="text-lg text-dark/80 font-light mb-4">Enter your email and password to continue earning</p>
        </div>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="mb-auto"
        >
          <Input
            fullWidth
            name="email"
            label="Email Address"
            placeholder="johntemitope@gmail.com"
            formState={formState}
            control={control}
          />
          <Input
            fullWidth
            name="password"
            label="Password"
            placeholder="**************"
            formState={formState}
            control={control}
            type="password"
            showPasswordIcon={true}
          />
          <div className="mt-4 mb-9 flex flex-row justify-between">
            <Link to='/register/start' className="text-base font-light text-dark/80 hover:text-dark/50">New Here? <span className="font-normal text-dark hover:text-dark/50">Sign up</span></Link>
            <Link to='/forgot-password' className="text-base font-normal text-dark hover:text-dark/50">Forgot Password?</Link>
          </div>
          <Button type="submit" state='primary' label="Continue" isLoading={isLoading} />
        </Form>
      </div>
    </AuthLayout>
  );
};

export default LoginAuth;
