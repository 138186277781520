import { useState } from 'react';
import SurveyLayout from "./layout"
import { Form, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Input from 'components/shared/Input';
import { eligibility } from 'utils/data';
import { Button } from 'components/shared/Button';
import Success from "assets/success-icon.svg"
import Error from "assets/error-icon.svg"

const Eligibility = () => {
  const navigate = useNavigate();
  const { handleSubmit, control, formState } = useForm();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isEligible, setIsEligible] = useState<boolean | null>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const question = eligibility[currentQuestionIndex];

  if (!question) {
    return <div>No question found</div>;
  }

  const onSubmit = (data: any) => {
    console.log('Form data:', data); // Handle form submission logic
    if (currentQuestionIndex < eligibility.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1); // Move to the next question
    } else {
      // Determine eligibility based on answers 
      // Add your own logic here
      const eligible = false; // Replace with actual eligibility logic
      setIsEligible(eligible);
      setModalVisible(true);
    }
  };


  const handlePrev = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleButtonRoute = () => {
    setModalVisible(false);
    if (isEligible) {
      navigate('/test/survey');
    } else {
      navigate('/dashboard');
    }
  };

  const options = [
    question.option1 ? { value: question.option1, label: question.option1 } : undefined,
    question.option2 ? { value: question.option2, label: question.option2 } : undefined,
    question.option3 ? { value: question.option3, label: question.option3 } : undefined,
    question.option4 ? { value: question.option4, label: question.option4 } : undefined,
  ].filter((option): option is { value: string; label: string } => option !== undefined);

  return (
    <SurveyLayout>
      <Form className='eligibility-form' layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Controller
          name={`eligibility_${question.id}`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="radio"
              label={question.question}
              options={options}
              control={control}
              formState={formState}
            />
          )}
        />
        <div className='flex '>
          {currentQuestionIndex > 0 && (
            <Button state='tetiary' label='Previous' onClick={handlePrev} />
          )}
          <Button state='primary' label={currentQuestionIndex < eligibility.length - 1 ? 'Next' : 'Submit'} type="submit" />
        </div>
      </Form>
      <Modal
        title={false}
        width={500}
        open={modalVisible}
        // onCancel={null}
        footer={null}
        centered
        closeIcon={null}
        closable={false}
      >
        <div className="">
          <img src={isEligible ? Success : Error} alt="Info Icon" />
        </div>
        <div className="">
          <p>{isEligible ? 'Congratulations, Temitope! You are eligible to take this survey.' : 'Unfortunately, you are not eligible for this survey. Thank you.'}</p>
        </div>
        <Button label={isEligible ? 'Take Survey' : 'Go to Dashboard'} state="primary" onClick={handleButtonRoute} />
      </Modal>
    </SurveyLayout>
  );
};

export default Eligibility;
