export function getPaginationText(limit: number, page: number, total: number) {
  let start = page;
  if (page > 1) start = limit * (page - 1) + 1;
  let end = start + limit - 1;
  if (start > total) start = total;
  if (end > total) end = total;

  if (!total) return "";

  return `Showing results ${start} - ${end} of ${total}`;
}

export const formatNumber = ({
  number,
  isCurrency = false,
  currency = "",
  notation = "standard",
}: {
  number: number;
  isCurrency?: boolean;
  currency?: "NGN" | "USD" | "GBP" | "";
  notation?: "compact" | "standard";
}): string => {
  const locale = "en-Gb";
  const numberToFormat = number || 0;
  return isCurrency && currency
    ? new Intl.NumberFormat(locale, {
      currency,
      currencyDisplay: "narrowSymbol",
      currencySign: "accounting",
      notation,
      style: "currency",
    }).format(numberToFormat)
    : new Intl.NumberFormat(locale, { notation }).format(numberToFormat);
};