import React from 'react';

interface RadioButton {
  label: string;
  value: number | string;
  name: string;
}

interface RadioButtonGroupProps {
  value: number | string;
  options: RadioButton[];
  onChange: (value: number | string) => void;
  disabled?: boolean;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ value, options, onChange, disabled }) => {
  const handleChange = (optionValue: number | string) => {
    onChange(optionValue);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {options.map(({ label, value: optionValue, name }, index) => (
        <label className='flex gap-2 items-center' key={index} style={{ marginBottom: '8px' }}>
          <input
            type="radio"
            name={name}
            value={optionValue}
            checked={value === optionValue}
            onChange={() => handleChange(optionValue)}
            disabled={disabled}
          />
          {label}
        </label>
      ))}
    </div>
  );
};

export default RadioButtonGroup;
