import { logoutApi } from 'services/api/authApi';

export const areObjectsEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const isObjectEmpty = (obj: Record<string, any>) => {
  return Object.entries(obj)?.length === 0;
};

export const capitalize = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('access_token')) {
      try {
        return localStorage.getItem('access_token')
      } catch (error) {
        return undefined;
      }
    }
  }
}

export const Logout = async (useRedirectUrl?: boolean) => {
  try {
    logoutApi();
    if (typeof window !== 'undefined') {
      localStorage.removeItem('techrevolve_user');
      localStorage.removeItem('techrevolve_email');
      window.location.href = '/';
      // if (useRedirectUrl) {
      //   window.location.href = `/?redirectTo=${window.location.pathname}${window.location.search}`;
      // } else {
      //   window.location.href = '/';
      // }
    }
  } catch (error) { }
};
