import Test from 'modules/test';

const index = () => {
  return (
    <div className='pt-4 pb-20'>
      <div className='px-4 sm:px-6 lg:px-8'><Test /></div>
    </div>
  )
};

export default index;