import { createContext, useContext, useState, ReactNode } from 'react';
import { survey } from '../utils/data';

export interface Test {
  id: number;
  duration: number;
  reward?: number;
  title?: string;
  heading?: string;
  description?: string;
  needsEligibility?: boolean;
}

interface TestContextType {
  tests: Test[];
  selectedTest: Test | null;
  setSelectedTest: (test: Test) => void;
  refreshTests: () => void;
  removeTest: (id: number) => void;
}

export const TestContext = createContext<TestContextType | undefined>(undefined);

interface TestProviderProps {
  children: ReactNode;
}

export const TestProvider = ({ children }: TestProviderProps) => {
  const [tests, setTests] = useState<Test[]>(survey);
  const [selectedTest, setSelectedTest] = useState<Test | null>(null);

  const refreshTests = () => {
    setTests([...survey]);
  };

  const removeTest = (id: number) => {
    setTests(tests.filter(test => test.id !== id));
  };

  return (
    <TestContext.Provider value={{ tests, selectedTest, setSelectedTest, refreshTests, removeTest }}>
      {children}
    </TestContext.Provider>
  );
};

export const useTestContext = (): TestContextType => {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error('useTestContext must be used within a TestProvider');
  }
  return context;
};
