import { LayoutProps } from 'types';
import { useNavigate } from 'react-router-dom';
import Logo from 'assets/techrevolve.svg'
import { ProfileDropDown } from 'components/shared/ProfileDropDown';
import { Button } from 'components/shared/Button';
import { ArrowLeft } from 'assets/svg/SvgIcons';
import { useTestContext } from 'context/TestContext';




const SurveyLayout: React.FC<LayoutProps> = ({ children }) => {
  // const { tests } = useTestContext();
  const navigate = useNavigate();
  const { selectedTest } = useTestContext();

  return (
    <>
      <div>

        <div className=''>
          <div className='sticky top-0 z-40 flex shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-6 lg:py-4 py-4 border-b-[1px]'>
            <div className='flex shrink-0 items-center w-auto justify-center'>
              <img className='h-7 w-40' src={Logo} alt='TechRevolve' />
            </div>
            <div className='flex  gap-x-4 justify-between items-center w-full'>
              <h3 className='text-sm lg:text-xl font-semibold text-center items-center lg:ml-[206.3px]'>{selectedTest && selectedTest.heading}</h3>
              <div className='flex gap-x-16'>
                <ProfileDropDown />
              </div>
            </div>
          </div>

          <div className='flex flex-col md:flex-row items-start mt-10 ml-4 md:ml-8 lg:ml-[143px]'>
            <Button label='Back Home' state='tetiary' className='bg-[#1e1e1e]/5 p-3' prefixIcon={<ArrowLeft />} onClick={() => navigate(-1)} />
            <div className='lg:ml-36'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyLayout;
