import { notification } from 'antd';

export const ErrorHandler = (error: any) => {
  //Network error
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
  }

  let message = '';
  let defaultMessage = 'Something went wrong, please try again.';
  if (error?.response) {
    let status = error.response.status;
    if (status >= 500 && status < 600) {
      notification.error({
        message: defaultMessage
      });
      return;
    }
    if (status.toString().startsWith('4')) {
      if (status === 401) {
        localStorage.removeItem('techrevolve_user');
        localStorage.removeItem('techrevolve_v_email');
        window.location.href = '/';
        return;
      } else if (status === 404) {
        message = error.response.data.message;
        return { message, status };
      } else {
        return error.response.data.message;
      }
    }
  } else {
    message = error?.message || defaultMessage;
  }
  return { message };
};

export const unAuthenticatedErrorHandler = (error: any) => {
  const { status, data } = error.response || {};
  let msg = '';
  if (status.toString().startsWith('4')) {
    msg = data?.message;
  } else if (status >= 500 && status < 600) {
    msg = 'Something went wrong, please try again.';
  }
  return msg;
};

export const isHttpNotFound = (error: any) => error?.status?.toString() === '404';
