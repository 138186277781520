import { ConfigProvider } from "antd";
import AppRoutes from "routes/AppRoutes";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Inter",
          colorPrimary: "#181B01",
        },
      }}
    >
      {/* <Loading loading={loading}/> */}
      <AppRoutes />
    </ConfigProvider>
  );
}

export default App;
