import './inputContainer.scss';

interface Option {
  value: string | any;
  label: string;
}

interface InputContainerProps {
  placeholder?: string;
  label?: string;
  type?: 'text' | 'select' | 'email' | 'date';
  options?: Option[];
  value?: string;
  disabled?: boolean;
  name?: string;
  hasError?: boolean;
  required?: boolean;
  error?: any;
  [key: string]: any;

}

const InputContainer: React.FC<InputContainerProps> = ({
  error,
  label,
  type,
  options,
  value,
  disabled,
  name,
  register,
  placeholder,
  ...rest
}) => {
  return (
    <div className='inputContainer'>
      <label className='labelInputContainer'>{label}</label>
      {type === 'select' ? (
        <select
          name={name}
          className='input'
          disabled={disabled}
          {...(register ? register(name) : {})}
        >
          <option value="" disabled hidden>
            Select an Option
          </option>
          {options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          placeholder={placeholder}
          className='input'
          name={name}
          type={type}
          value={value}
          {...(register ? register(name) : {})}
          disabled={disabled}
          {...rest}
        />
      )}
      <p className='text-red-500 text-xs mt-2'>{error}</p>
    </div>
  );
};

export default InputContainer;