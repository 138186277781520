import AvailableTest from 'modules/test/take-test';

const TakeTest = () => {

  return (
    <div className='pt-4 pb-20'>
      <div className='px-4 sm:px-6 lg:px-8'><AvailableTest /></div>
    </div>
  )
};

export default TakeTest;