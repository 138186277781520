import { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import { ClockIcon, Refresh } from "assets/svg/SvgIcons";
import { Button } from "components/shared/Button";
import { TestCard } from "components/test/TestCard";
import { Close } from "assets/svg/SvgIcons";
import { useTestContext } from 'context/TestContext';
import { Test } from 'context/TestContext';
import { getColor, getTextColor } from 'utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import Info from 'assets/info-icon.svg'


const AvailableTest = () => {
  const navigate = useNavigate()
  const { tests, selectedTest, setSelectedTest, refreshTests } = useTestContext();//removeTest
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEligibilityModalVisible, setIsEligibilityModalVisible] = useState(false);
  // const [isEligible, setIsEligible] = useState(false);

  const handleTestModal = (test: Test) => {
    // console.log('Opening Test Modal for:', test);
    setSelectedTest(test);
    setIsModalVisible(true);
  }

  const handleEligibilityModal = (test: Test) => {
    // console.log('Opening Eligibility Modal for:', test);
    setSelectedTest(test);
    setIsEligibilityModalVisible(true);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEligibilityModalVisible(false);
  };

  const handleCardClick = (test: Test) => {
    // console.log('Card Clicked:', test);
    if (test.needsEligibility) {
      handleEligibilityModal(test);
    } else {
      handleTestModal(test);
    }
  }

  // const handleEligibilityCompletion = (isEligible: boolean) => {
  //   setIsEligible(isEligible);
  //   if (isEligible) {
  //     handleTestModal(selectedTest!);
  //   } else {
  //     // Handle failed eligibility scenario, e.g., navigate to dashboard
  //   }
  //   setIsEligibilityModalVisible(false);
  // }


  const handleTakeSurvey = () => {
    navigate('/test/survey')
  }
  const handleTakeEligibility = () => {
    navigate('/test/eligibility')
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-5">
        <div>
          <h1 className="text-dark text-[32px]">Available tests</h1>
          <p className="text-[#111]/80 font-light text-base">Give honest feedbacks for a better product experience</p>
        </div>
        <div>
          <Button className="" prefixIcon={<Refresh />} label='Refresh' onClick={refreshTests} />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-custom-2 lg:grid-cols-custom-2 xl:grid-cols-3 gap-y-4 gap-x-4 cursor-pointer">
        {tests.map(test => (
          <TestCard key={test.id} test={test} onClick={() => handleCardClick(test)} />
        ))}
      </div>
      <Modal
        title={false}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<Close />}
        centered
        width={500}
      >
        {selectedTest && (
          <>
            <h1 className='pt-20 text-[28px] font-semibold -tracking-[0.04] leading-[38.4px]'>{selectedTest.heading}</h1>
            <p className='text-sm font-light text-[#181B01] mb-4'>{selectedTest.description}</p>
            <div className='mb-4'>
              <div className='flex justify-between items-center mb-2'>
                <span className='-tracking-[0.04] text-sm font-medium'>Price</span>
                <span className=' text-2xl font-semibold -tracking-[0.02]'>$30</span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='-tracking-[0.04] text-sm font-medium'>Duration</span>
                <span className="flex rounded-[10px] justify-center items-center py-[1px] px-2 gap-x-[2px]" style={{ backgroundColor: getColor(selectedTest.duration) }}>
                  <span><ClockIcon color={getTextColor(selectedTest.duration)} /></span>
                  <p className="text-[12px]" style={{ color: getTextColor(selectedTest.duration) }}>{selectedTest.duration} minutes</p>
                </span>
              </div>
            </div>
            <div className='mb-4'>
              <h6 className=' text-base font-semibold'>Test Requirement</h6>
              <ul className='list-disc pl-5 text-sm font-normal leading-[23.8px] gap-1'>
                <li>Tester must be 13 years and above.</li>
                <li>Tester must be from West Africa.</li>
                <li>Tester must be using a desktop or PC for easy access to questions.</li>
              </ul>
            </div>
            <div className="flex items-center gap-2 justify-between mb-6">
              <Checkbox
              >
                I have read and understood the requirement for the survey
              </Checkbox>
            </div>
            <Button state='primary' label='Start Survey' onClick={handleTakeSurvey} />
            {/* <Button state='primary' label='Start survey' onClick={() => removeTest(selectedTest.id)} /> */}
          </>
        )}
      </Modal>
      {/* <Modal
        title="Eligibility Required"
        open={isEligibilityModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<Close />}
        width={500}
      >
        <h1 className='pt-20 text-[28px] font-semibold -tracking-[0.04]'>Eligibility Survey</h1>
        <p className='text-sm font-light text-[#181B01] mb-4'>Please complete the eligibility survey to proceed.</p>
        <div></div>
        <Button state='primary' label='Start eligibility survey' onClick={() => handleEligibilityCompletion(true)} />
        <Button state='secondary' label='Fail eligibility survey' onClick={() => handleEligibilityCompletion(false)} />
      </Modal> */}
      <Modal
        title={false}
        open={isEligibilityModalVisible}
        // onCancel={handleCancel}
        footer={null}
        closeIcon={null}
        closable={false}
        width={500}
        centered
      >
        <img className='pt-5' src={Info} alt="" />
        <h1 className=' text-[32px] pt-6 font-semibold -tracking-[0.04] leading-[38.4px]'>This survey requires you to take an eligibility test to qualify</h1>
        <div className='flex items-center gap-4 mt-7'>
          <Link className='text-sm font-medium' to={'/dashboard'}> Back to home</Link>
          <Button state='primary' label='Take eligibility test' onClick={handleTakeEligibility} />
        </div>
        {/* <Button state='primary' label={isEligible ? 'Start survey' : 'Go to dashboard'} onClick={() => removeTest(selectedTest!.id)} /> */}
      </Modal>
    </div>
  );
};

export default AvailableTest;
