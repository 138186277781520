import { Form, notification } from "antd";
import Input from "components/shared/Input";
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'components/shared/Button';
import AuthLayout from "components/layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordFormPayload } from "types/auth-types";
import { forgotPasswordFormValidationSchema } from "utils/validationSchemas/authSchema";
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordApi } from "services/api/authApi";
import { useMutation } from '@tanstack/react-query';


const ForgotPasswordAuth = () => {

  const navigate = useNavigate();

  const { reset, handleSubmit, control, formState } = useForm<ForgotPasswordFormPayload>({
    resolver: yupResolver(forgotPasswordFormValidationSchema),
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormPayload> = (data) => {
    handleForgotPassword(data);
    localStorage.setItem('email', data.email);
    console.log('email', data.email)
  };

  const { mutate: handleForgotPassword } = useMutation({
    mutationFn: forgotPasswordApi,
    onSuccess: ({ message }) => {
      notification.success({ message: message });
      reset();
      navigate('/verify-password');
    },
    onError: (error) => {
      notification.error((error));
    },
  });

  return (
    <AuthLayout>
      <div>
        <div className=" mt-32 text-left">
          <h1 className=" text-4xl text-dark mb-2 font-medium">Forgot password</h1>
          <p className=" text-lg text-dark/80 font-light mb-4">Enter your email to reset password </p>
        </div>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="mb-auto"
        >
          <Input
            fullWidth
            name="email"
            label="Email or Username"
            placeholder="johntemitope@gmail.com "
            formState={formState}
            control={control}
          />
          <div className="flex flex-row items-center mt-8">
            <Button type="submit" state='primary' label="Continue" />
            <Button type="button" onClick={() => navigate(-1)} state='tetiary' label="Back" />
          </div>
        </Form>
      </div>
    </AuthLayout>
  )
};
export default ForgotPasswordAuth
