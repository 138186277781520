import { useMutation, useQuery } from '@tanstack/react-query';
import { Axios, instance } from '../axios/axiosInstance';
import { ConnectBankFormPayload, VerifyAccountFormPayload } from 'types/payment-types';
import { apiRequestParams, UnknownObjectType } from '.';
import { notification } from 'antd';

const formatError = ({ message }: { message: string }) => {
  console.log(message);
};

export const useBanksApi = () => {
  const fetchBanks = async () => {
    const { data } = await instance.get(`/fetch-banks`, { params: { length: Number.MAX_SAFE_INTEGER } });
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['fetch-banks'],
    queryFn: fetchBanks,
  });

  return { data, isLoading };
};
export const useVerifyAccountApi = () => {
  const verifyAccount = async (payload: VerifyAccountFormPayload) => {
    const { data } = await Axios.post(`/verify-account`, payload);
    return data;
  };

  const { mutate, data, isPending: isLoading, isError } = useMutation({
    mutationFn: verifyAccount,
  });

  return { verifyAccount: mutate, data, isLoading, isError };
};


export const connectAccountApi = async (payload: ConnectBankFormPayload) => {
  const { data } = await Axios.post(`/connect-bank-account`, payload, {
  });
  return data;
};

export const useConnectAccountApi = ({ onSuccess }: apiRequestParams) => {
  const { mutate: handleConnectAccount, isPending: isConnectingAccount } = useMutation({
    mutationFn: async (requestPayload: ConnectBankFormPayload) => {
      const { data } = await Axios.post(
        `/connect-bank-account
      `,
        requestPayload
      );
      return data?.data;
    },
    onSuccess,
    onError: formatError
  });

  return { handleConnectAccount, isConnectingAccount };
};
