import { Form, notification } from "antd";
import Input from "components/shared/Input";
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'components/shared/Button';
import AuthLayout from "components/layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import { ResetPasswordFormPayload } from "types/auth-types";
import { verifyResetPasswordTokenApi } from "services/api/authApi";
import { useMutation } from '@tanstack/react-query';
import { unAuthenticatedErrorHandler } from 'services/axios/errorHandler';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordFormValidationSchema } from "utils/validationSchemas/authSchema";
import { useAuth } from "hooks";


const ResetPasswordAuth = () => {
  const navigate = useNavigate();
  const { OTP: contextOTP } = useAuth();

  const { handleSubmit, control, formState } = useForm<ResetPasswordFormPayload>({
    resolver: yupResolver(resetPasswordFormValidationSchema),
  });

  const onSubmit: SubmitHandler<ResetPasswordFormPayload> = (data) => {
    const OTPstorage = localStorage.getItem('otp');
    const storedOTP = contextOTP || OTPstorage;
    const email = localStorage.getItem('email');
    console.log('OTP from previous page or local storage:', storedOTP);
    console.log('email from previous page or local storage:', email);

    if (storedOTP && email) {
      handleResetPassword({ ...data, verification_code: storedOTP, email: email });
      localStorage.removeItem('otp');
      localStorage.removeItem('email');
    } else {
      notification.error({ message: "Verification code is missing. Please verify your email again." });
      navigate('/forgot-password');
    }

  };

  const { mutate: handleResetPassword } = useMutation({
    mutationFn: verifyResetPasswordTokenApi,
    onSuccess: ({ message }) => {
      notification.success({ message });
      navigate('/');
    },
    onError: (error) => {
      notification.error({ message: unAuthenticatedErrorHandler(error) });
    },
  });
  return (
    <AuthLayout>
      <div>
        <div className=" mt-32 text-left">
          <h1 className=" text-4xl text-dark mb-2 font-medium">Change password</h1>
          <p className=" text-lg text-dark/80 font-light mb-4">Enter new password </p>
        </div>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="mb-auto"
        >
          <Input
            fullWidth
            name="password"
            label="Enter new password"
            placeholder="**************"
            formState={formState}
            control={control}
            type="password"
            showPasswordIcon={true}
          />
          <Input
            fullWidth
            name="confirm_password"
            label="Confirm new password"
            placeholder="**************"
            formState={formState}
            control={control}
            type="password"
            showPasswordIcon={true}
          />
          <div className="flex flex-row items-center mt-12">
            <Button type="submit" state='primary' label="Continue" />
            <Button type="button" onClick={() => navigate(-1)} state='tetiary' label="Back" />
          </div>
        </Form>
      </div>
    </AuthLayout>
  );
};

export default ResetPasswordAuth;