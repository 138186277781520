import axios, { AxiosResponse } from 'axios';
import { ErrorHandler, unAuthenticatedErrorHandler } from './errorHandler';
import { getAccessToken } from 'utils/helpers';

export const baseURL = `${process.env.REACT_APP_BASE_URL}`;

//UNAUTHENTICATED CALLS
export const instance = axios.create({
  baseURL,
});

const successHandler = (response: AxiosResponse) => {
  return response;
};

instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => Promise.reject(unAuthenticatedErrorHandler(error))
);

//AUTHENTICATED CALLS
export const Axios = axios.create({
  baseURL,
})

Axios.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => successHandler(response),
  async function (error) {
    return (ErrorHandler(error))
  }
);