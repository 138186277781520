import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Bars2Icon } from '@heroicons/react/24/outline'
import { handleLogout } from 'utils/handleLogout';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks';


interface profileDropDownProps {
  variant?: 'primay' | 'secondary';
  showSettings?: boolean;
  dropdownPlacementOnMobile?: 'default' | 'left';
}

export const ProfileDropDown: React.FC<profileDropDownProps> = ({
  variant = 'primary',
  showSettings = true,
  dropdownPlacementOnMobile = 'default', //For small screens
}) => {
  const { user } = useAuth();


  // const user = {
  //   firstName: 'Olawale',
  //   lastName: 'Oguntayo',
  //   avatar: "avatar-icon.svg",
  //   email: 'olawale@yahoo.com',
  // };


  const [isSigningOut, setIsSigningOut] = useState<boolean>(false);

  const { first_name, last_name, email, avatar } = user || {};

  return (
    <>
      <Menu as='div' className='relative ml-3'>
        <div>
          <Menu.Button className='relative flex'>
            <div
              className={`w-fit flex justify-center items-center gap-3 cursor-pointer rounded-[30px] px-2 py-2 bg-gray-100`}>
              {!!avatar ? (
                <img src={`/images/${avatar}`} alt='' className='rounded-full w-[34px] h-[34px]' />
              ) : (
                <div
                  className={`w-[34px] h-[34px] rounded-full flex justify-center items-center font-semibold text-white uppercase bg-black`}>
                  {first_name?.charAt(0)}
                  {last_name?.charAt(0)}
                </div>
              )}
              <div>
                <Bars2Icon className={`h-6 w-6 text-[#1e1e1e]/40`} />
              </div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'>
          <Menu.Items
            className={`absolute sm:-left-40  z-10 mt-2 w-[230px] origin-top-right rounded-xl bg-white pb-8 shadow-xl
              -left-40
          `}>
            <Link to='/profile'>
              <div className='bg-gray-100 border border-primary-light px-4 pt-6 pb-3 rounded-tr-xl rounded-tl-xl'>
                <p className='font-semibold text-sm capitalize'>
                  {' '}
                  {first_name} {last_name}
                </p>
                <p className='text-[10px]'>{email}</p>
              </div>
            </Link>
            <div className={`px-4 pt-6}`}>
              <div
                className='flex justify-between cursor-pointer mt-6'
                onClick={() => {
                  setIsSigningOut(true);
                  handleLogout()
                }}
              >
                <p className='text-[12px]'>{isSigningOut ? 'Signing Out...' : 'Sign Out'}</p>
                <span className='flex h-5 w-5 justify-center items-center bg-black rounded-full'>
                  <ChevronRightIcon className='text-white h-3 w-3' />
                </span>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
