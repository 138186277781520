// import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'context/AuthContext';
import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { TestProvider } from 'context/TestContext';

const Provider = ({ children }: { children: React.ReactNode }) => {
  const defaultOptions = {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      keepPreviousData: true,
    },
  };

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions,
      })
  );

  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <TestProvider>
          {children}
        </TestProvider>
      </AuthProvider>
      {/* <ToastContainer /> */}
    </QueryClientProvider>
  );
};

export default Provider;