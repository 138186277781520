import { Button } from 'components/shared/Button';
import OTPInput from 'react-otp-input';
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from 'components/layouts/AuthLayout';
import { notification } from 'antd';
import { useAuth } from "hooks";


// type FormValues = {
//   otp: string;
// }



const VerifyPasswordAuth = () => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState<string>("");
  const { setOTP: saveOTPToContext } = useAuth();
  const email = localStorage.getItem('email') || localStorage.getItem('techrevolve_email') || "";



  const renderInput = ({ inputStyle, ref, ...rest }: any, index: number) => (
    <input
      {...rest}
      ref={ref as React.RefObject<HTMLInputElement>}
      className={`custom-input-class ${inputStyle}`}
      placeholder={'123456'[index]}
      inputMode="numeric"
      pattern="[0-9]*"
      type="tel"
    />
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (OTP.length === 6) {
      saveOTPToContext(OTP);
      localStorage.setItem('otp', OTP);
      console.log(OTP)
      notification.success({ message: "Code Accepted. Please set your new password." });
      navigate('/reset-password');
    } else {
      notification.error({ message: "Invalid OTP. Please check and try again." });
    }
  };

  return (
    <AuthLayout>
      <div>
        <div className=" mt-32 text-left">
          <h1 className=" text-4xl text-dark mb-2 font-medium">Verify Email</h1>
          <p className=" text-lg text-dark/80 font-light mb-4">Enter the code sent to <Link className="underline decoration-solid" to={""}>{email}</Link>. <span className=" font-medium">Change Email</span> </p>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <OTPInput
              onChange={setOTP}
              value={OTP}
              numInputs={6}
              inputStyle="inputStyle"
              renderInput={renderInput}
            />
            <div className="flex flex-row items-center mt-8">
              <Button type="submit" isDisabled={OTP.length < 6} state='primary' label="Verify Email" />
              <Button type="button" onClick={() => navigate(-1)} state='tetiary' label="Back" />
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  )
}

export default VerifyPasswordAuth