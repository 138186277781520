import { Tag } from 'antd';
import { DashboardCardProps } from 'types/dashboard-types';
import righticon from 'assets/right-point.svg'



export const DashboardCard: React.FC<DashboardCardProps> = ({ cardImage, title, descriptionText, buttonText, descriptionWidthClass, onClick }) => {
  return (
    <div className='bg-white rounded-xl w-[330px] sm:w-[360px] md:w-full xl:w-auto border-[1px] border-[#F0F0F0]'>
      <div className=' py-4 px-5'>
        <img src={cardImage} alt="cardPic" />
      </div>
      <div className='bg-white pb-4 px-5 rounded-b-xl h-[175px]'>
        <Tag color="#E6FF00" className=' text-dark mb-2'> <span className='text-dark'>5 minutes</span></Tag>
        <h1 className='text-lg font-semibold'>{title}</h1>
        <p className={`text-sm text-[#595959] font-light mb-4 ${descriptionWidthClass}`}>{descriptionText}</p>
        <button onClick={onClick} type='button' className='flex flex-row items-center gap-x-[10px] text-dark border-solid border-[0.5px] border-[#41423E1A] rounded-[8px] py-2 px-4'>{buttonText}
          <img src={righticon} alt="" />
        </button>
      </div>
    </div>
  )
};
