import Message from 'modules/message';

const Messages = () => {
  return (
    <div className='pt-4 pb-20'>
      <div className='px-4 sm:px-6 lg:px-8'><Message /></div>
    </div>
  )
};

export default Messages;