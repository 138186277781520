import { DashboardCard } from 'components/dashboard';
import createprofile from 'assets/create-profile-icon.svg'
import connectbank from 'assets/connect-bank-icon.svg'
import { useAuth } from 'hooks';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { Close } from "assets/svg/SvgIcons";
import 'styles/modal.scss'
import { Button } from 'components/shared/Button';
import Waiting from 'assets/waiting-pana.svg'
import { ConnectBankModal } from 'components/dashboard/ConnectBankModal';
import { useGetUserApi } from 'services/api/userApi';

const DashboardModules = () => {
  const { user, handleUserUpdate } = useAuth();
  const { data: updatedUserData } = useGetUserApi();
  const navigate = useNavigate()
  const isModalShownBefore = localStorage.getItem('techrevolve_modal_shown') === 'true';
  const [isModalVisible, setIsModalVisible] = useState(!isModalShownBefore);
  const [connect, setConnect] = useState(false);

  const { bank_verified, has_completed_profile } = user

  useEffect(() => {
    if (updatedUserData) {
      handleUserUpdate(updatedUserData?.entity);
    }
  }, [updatedUserData]);

  useEffect(() => {
    if (!isModalShownBefore) {
      localStorage.setItem('techrevolve_modal_shown', 'true');
    }
  }, [isModalShownBefore]);

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setConnect(false);
  };

  const handleCreateProfile = () => {
    navigate('/profile')
  }

  // const handleConnect = () => {
  //   if (has_completed_profile === false) {
  //     setConnect(false);
  //   } else {
  //     setConnect(true);
  //   }
  // }


  return (
    <div>
      {bank_verified && has_completed_profile ? (
        <div className="flex flex-col justify-center items-center max-w-[500px] mx-auto text-center py-24">
          <img src={Waiting} alt="Feedback Design" className="" />
          <h2 className="text-[28px] font-semibold text-dark mt-8">Ready to give feedback?</h2>
          <p className="text-[#111]/80">Keep this page open and listen for the ding that tells you when a new test is available.</p>
        </div>
      ) : (
        <div>
          <h1 className="text-dark text-[32px]">Get Started with TechRevolve</h1>
          <p className="text-[#494C33] font-light text-base mb-[60px]">Welcome to TechRevolve! Please proceed to complete the registration process below in order to begin your journey of taking tests.</p>
          {/* <div className='flex flex-col md:flex-row justify-center md:justify-start gap-4'> */}
          <div className='grid lg:grid-rows-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4'>
            {!has_completed_profile && (
              <DashboardCard
                cardImage={createprofile}
                title="Create your Profile"
                descriptionText="Create your profile to get matching tests"
                buttonText="Create Profile"
                descriptionWidthClass="lg:w-[250px]"
                onClick={handleCreateProfile}
              />
            )}
            {!bank_verified && (
              <div>
                <DashboardCard
                  cardImage={connectbank}
                  title="Connect your Bank"
                  descriptionText="We utilize your bank account for secure test payments."
                  buttonText="Connect Bank"
                  // onClick={handleConnect}
                  onClick={() => setConnect(true)}
                />
                <ConnectBankModal isVisible={connect} onClose={handleModalCancel} />
              </div>
            )}
            {/* <DashboardCard
          cardImage={practice}
          title="Take Practise Test"
          descriptionText="Start earning money by taking the practice test."
          buttonText="Take Test"
          onClick={handleTakeTest}
        /> */}
          </div>
        </div>
      )}
      <Modal
        title={false}
        open={isModalVisible}
        onCancel={handleModalCancel}
        closeIcon={<Close />}
        footer={null}
        width={500}
        centered
      >
        <h1 className=' pt-20 text-[32px]'>Welcome to TechRevolve</h1>
        <p className='text-sm font-light text-dark mb-8'>To gain access to tests on your dashboard, you must complete a two-step registration process, which is a prerequisite for account approval.</p>
        <Button onClick={handleModalCancel} label='Continue' />
      </Modal>
    </div>

  );
};

export default DashboardModules;