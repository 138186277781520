import { LayoutProps } from 'types';
import { IUser } from 'types/user-types';
import React, { createContext, useState } from 'react';

export interface IAuthState {
  user: IUser;
  OTP: string;
}

export interface AuthContextType {
  user: IUser;
  setUser: (userData: IUser) => void;
  handleUserUpdate: (user: IUser) => void;
  OTP: string;
  setOTP: (OTP: string) => void;
  isAuthenticated: boolean;
}

let initialUser = {} as IUser;


if (typeof window !== 'undefined') {
  if (localStorage.getItem('techrevolve__user')) {
    try {
      initialUser = JSON.parse(localStorage.getItem('techrevolve__user') || '');
    } catch (error) { }
  }
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<LayoutProps> = ({ children }) => {
  const [user, setUser] = useState<IUser>(initialUser);
  const [OTP, setOTP] = useState<string>('');
  const isAuthenticated = JSON.stringify(user) !== `{}`


  const handleUserUpdate = (newUser: IUser) => {
    setUser(newUser);
    localStorage.setItem('techrevolve_user', JSON.stringify(newUser));
  };

  return (
    <AuthContext.Provider value={{ user, setUser, handleUserUpdate, OTP, setOTP, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};