import CompleteProfile from "modules/profile"

const Profile = () => {
  return (
    <div className='pt-12 pb-20'>
      <div className='px-4 sm:px-6 lg:px-[122px]'><CompleteProfile /></div>
    </div>
  )
}

export default Profile