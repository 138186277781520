import { Accordion } from 'components/home/Accordion';
import Tabs from 'components/shared/Tabs';
import { useState } from 'react'
import Diagonal from 'assets/diagonal-arrow.svg'

const questionAndAnswers = [
  {
    question: 'What is TechRevolve?',
    answer:
      'Techrevolve is a platform where individuals and businesses can get actionable feedback from current or prospective users. The companies are able to make data driven decisions and build user centric products while the users get compensated for their time. ',
  },
  {
    question: 'Who is Techrevolve for?',
    answer:
      'Techrevolve is for anyone and everyone; students looking to administer a questionnaire to a particular demographic, FMCG companies, companies with digital products, businesses rendering services.',
  },
  {
    question: 'How much can I earn on a survey?',
    answer:
      'Each survey would have the amount a user can earn written boldly on the survey project. ',
  },
  {
    question: 'What geographical region are participants from?',
    answer:
      "For now, most users are located in Africa.  although we plan to expand to other countries.",
  },
  {
    question: 'Do I need to register before getting access to surveys?',
    answer:
      "Yes. We do this to ensure a seamless research experience.",
  },
  {
    question: 'How do I receive payments on a survey?',
    answer:
      "Payments are made to the account details provided at the initial registration.",
  },
  {
    question: 'Can I run multiple surveys simultaneously?',
    answer:
      "Yes, you can run more than one survey at the same time.",
  },
];


const HelpModules = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleRedirectMail = () => {
    window.location.href = 'mailto:support@usetechrevolve.com';
  }

  return (
    <div>
      <nav>
        <div className='yaraa-tabs flex justify-between items-center'>
          <Tabs
            onChange={(key) => setActiveTab(key)}
            className="ant ant-tabs-tab"
            moreIcon={null}
            tabItems={[
              {
                component: null,
                data: {
                  tabKey: "all",
                  tab: (
                    <span className="techrevolve-tab-title">
                      All{" "}
                    </span>
                  ),
                },
              },
            ]}
          />
          <div onClick={handleRedirectMail} className='cursor-pointer'>
            <div className='flex gap-x-2 text-sm border border-[#D9D9D9] w-fit py-3 px-4 rounded-[10px] '>
              <p>Contact support</p>
              <img src={Diagonal} alt="" />
            </div>
          </div>
        </div>
      </nav>

      <section className='pb-20' >
        <div className='px-3 sm:px-5 lg:px-7'>
          <h1 className="text-dark text-[32px]">Get all your questions answered</h1>
          <p className="text-[#494C33] font-light text-base mb-8">Find answers, troubleshoot issues, and get the support you need—all in one place.</p>
          {activeTab === "all" && (
            <div className='pt-3 space-y-3  flex flex-col '>
              {questionAndAnswers.map(({ question, answer }, key) => (
                <Accordion
                  key={key}
                  title={question}
                  className='px-4 py-3 border border-[#41423E]/10 bg-white'
                  content={<p className=''>{answer}</p>}
                  isActive={activeIndex === key}
                  handleToggleAccordion={() => {
                    activeIndex === key ? setActiveIndex(null) : setActiveIndex(key);
                  }}
                />
              ))}
            </div>
          )}
        </div>

      </section>
    </div>
  );
};

export default HelpModules;

