import { Modal, Form, notification, message } from 'antd';
import { Button } from 'components/shared/Button';
import { Close } from "assets/svg/SvgIcons";
import 'styles/modal.scss'
import Input from 'components/shared/Input';
import { SubmitHandler, useForm } from "react-hook-form";
import { connectAccountApi, useBanksApi, useConnectAccountApi, useVerifyAccountApi } from 'services/api/paymentApi';
import { useEffect, useMemo, useState } from 'react';
import { ConnectBankFormPayload } from 'types/payment-types';
import { connectBankFormValidationSchema } from 'utils/validationSchemas/authSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import Success from "assets/success-icon.svg"
// import { unAuthenticatedErrorHandler } from 'services/axios/errorHandler';


interface ConnectBankModalProps {
  isVisible: boolean;
  onClose: () => void;
}
interface Bank {
  code: string;
  name: string;
}

export const ConnectBankModal: React.FC<ConnectBankModalProps> = ({ isVisible, onClose }) => {
  const [accountName, setAccountName] = useState<string | null>(null);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState<boolean>(false);


  const { data: banks, isLoading: bankLoading } = useBanksApi();
  const { verifyAccount } = useVerifyAccountApi();

  const bankOptions = useMemo(() => {
    if (!banks || typeof banks !== 'object') {
      return [];
    }
    return Object.values(banks).map((bank) => ({
      value: (bank as Bank).code,
      label: (bank as Bank).name,
    }));
  }, [banks]);

  const { handleSubmit, control, formState, setValue, watch } = useForm<ConnectBankFormPayload>({
    resolver: yupResolver(connectBankFormValidationSchema),
  });

  const accountNumber = watch("account_number");
  const bankCode = watch("bank_code");


  // useEffect(() => {
  //   if (accountNumber?.length === 10 && bankCode) {
  //     verifyAccount({
  //       account_number: accountNumber,
  //       bank_code: bankCode
  //     });

  //   }
  // }, [accountNumber, bankCode, verifyAccount]);

  // useEffect(() => {
  //   if (verifiedData?.status === true) {
  //     const accountName = verifiedData.entity.account_name;
  //     setAccountName(accountName);
  //     setValue("account_name", accountName);
  //     notification.info({ message: verifiedData.message });
  //     console.log(verifiedData.status, "status");
  //   } else if (accountNumber?.length === 10 && bankCode && !verifyLoading) {
  //     notification.error({ message: verifiedData?.message });
  //   }
  // }, [verifiedData, setValue, verifyLoading, bankCode]);

  useEffect(() => {
    if (accountNumber?.length === 10 && bankCode) {
      verifyAccount(
        { account_number: accountNumber, bank_code: bankCode },
        {
          onSuccess: (data) => {
            if (data.status === true) {
              const accountName = data.entity.account_name;
              setAccountName(accountName);
              setValue("account_name", accountName);
              notification.info({ message: data.message });
            } else {
              notification.error({ message: data.message });
            }
          },
          onError: ({ message }: { message: string }) => {
            // console.log(error, "error")
            // const errorMessage = message || 'Sorry, we could not find this account number';
            notification.error({ message: message });

          },
        }
      );
    }
  }, [accountNumber, bankCode, setValue, verifyAccount]);



  const onSubmit: SubmitHandler<ConnectBankFormPayload> = (data) => {
    handleConnectAccount(data);
  };

  // const { mutate: handleConnectBank, isPending: isConnecting } = useMutation({
  //   mutationFn: connectAccountApi,
  //   onSuccess: (response) => {
  //     if (response.status === true) {
  //       onClose();
  //       setIsSuccessModalVisible(true);
  //     } else {
  //       const errorMessage = response?.entity?.message || 'Account name does not match the user\'s name';
  //       notification.error({ message: errorMessage });
  //     }
  //   },
  //   onError: ({ message }: { message: string }) => {
  //     let errorMessage = 'Invalid account';
  //     notification.error({ message: message });
  //   },
  // });

  const { handleConnectAccount, isConnectingAccount } = useConnectAccountApi({
    onSuccess: ({ message }: { message: string }) => {
      notification.success({ message: message })
    },
  });

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
  };

  return (
    <>
      <Modal
        title={false}
        open={isVisible}
        onCancel={onClose}
        // closable={false}
        footer={null}
        width={500}
        closeIcon={<Close />}
        centered
      >
        <h1 className=' pt-14 text-[32px] font-semibold'>Connect your Bank</h1>
        <p className='text-sm font-light text-[#8C8C8C] mb-8'>Safely link your bank account to receive payments for tests. Ensure the account you&apos;re connecting is yours. </p>
        <p className=' text-xs font-light text-[#8C8C8C] mb-3'>BANK INFORMATION </p>
        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="mb-auto"
        >
          <Input
            fullWidth
            type="select"
            name="bank_code"
            label="Select Bank"
            placeholder="Select a bank"
            formState={formState}
            control={control}
            loading={bankLoading}
            options={bankOptions} />
          <Input
            maxLength={10}
            fullWidth
            name="account_number"
            label="Account Number"
            placeholder="Select a bank"
            formState={formState}
            control={control} />
          <Input
            fullWidth
            name="account_name"
            label="Account Holder's Name"
            placeholder="Account Name"
            formState={formState}
            control={control}
            value={accountName || ''}
            disabled
          />
          <div className='flex flex-row '>
            <Button state='tetiary' label='Back' onClick={onClose} />
            <Button state='primary' label='Connect Bank' isLoading={isConnectingAccount} type="submit" />
          </div>
        </Form>
      </Modal>
      <Modal
        open={isSuccessModalVisible}
        onCancel={handleSuccessModalClose}
        closeIcon={null}
        footer={[
          <Button key="ok" state='primary' label='Go to Dashboard' onClick={handleSuccessModalClose} />
        ]}
        width={500}
        centered
      >
        <div className="mb-6">
          <img src={Success} alt="Success Icon" />
        </div>
        <p className='text-[32px] leading-[38.4px] -tracking-[0.04] font-semibold text-dark mb-8'>Your bank account has been successfully connected!</p>
      </Modal>
    </>
  );
};