import React, { useState } from 'react';
import { Form } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import Input from 'components/shared/Input';
import { Button } from 'components/shared/Button';
import { survey } from 'utils/data';
import SurveyLayout from "./layout"

const Survey = () => {
  // const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { handleSubmit, control, formState } = useForm();

  const questionsPerPage = 2;
  const startIndex = (currentPage - 1) * questionsPerPage;
  const endIndex = startIndex + questionsPerPage;
  const currentQuestions = survey.slice(startIndex, endIndex);

  const onSubmit = (data: any) => {
    console.log('Form data:', data); // Handle form submission logic
    if (endIndex < survey.length) {
      setCurrentPage(currentPage + 1); // Move to the next page
    } else {
      console.log('Form submitted', data);
      // Handle final form submission here
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  return (
    <SurveyLayout>
      <Form className='survey-form' layout="vertical" onFinish={handleSubmit(onSubmit)}>
        {currentQuestions.map((question, index) => (
          <Controller
            key={index}
            name={`survey_${question.id}`}
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                control={control}
                type="radio"
                label={question.question}
                options={[
                  { value: question.option1, label: question.option1 },
                  { value: question.option2, label: question.option2 },
                  { value: question.option3, label: question.option3 },
                  { value: question.option4, label: question.option4 },
                ]}
                formState={formState}
              />
            )}
          />
        ))}
        <div className='flex '>
          {currentPage > 1 && (
            <Button state='tetiary' label='Previous' onClick={handlePrev} />
          )}
          <Button state='primary' label={endIndex < survey.length ? 'Next' : 'Submit'} type="submit" />
        </div>
      </Form>
    </SurveyLayout>
  );
};

export default Survey;
