import { LayoutProps } from 'types';
import { useLocation, Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react'
import Logo from 'assets/techrevolve.svg'
import Profile from 'assets/profile.svg'
import { ProfileDropDown } from 'components/shared/ProfileDropDown';
// import { Textfield } from 'components/shared/Textfield';
import { useAuth } from 'hooks';

const navigation = [
  {
    name: 'Dashboard',
    href: '/dashboard',
    defaultIcon: 'dashboard-icon.svg',
    activeIcon: 'dashboard-icon-active.svg',
  },
  {
    name: 'Test History',
    href: '/test-history',
    defaultIcon: 'test-history-icon.svg',
    activeIcon: 'test-history-icon-active.svg',
  },
  {
    name: 'Messages',
    href: '/messages',
    defaultIcon: 'messages-icon.svg',
    activeIcon: 'messages-icon-active.svg',
  },
  {
    name: 'Help Center',
    href: '/help',
    defaultIcon: 'help-center-icon.svg',
    activeIcon: 'help-center-icon-active.svg',
  },
];

const DashboardLayout: React.FC<LayoutProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeRouteName, setActiveRouteName] = useState('');
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    const activeRoute = navigation.find(route => location.pathname.includes(route.href));

    if (activeRoute) {
      setActiveRouteName(activeRoute.name);
    }
  }, [location.pathname]);

  const { first_name } = user || {};



  // const data = getUserApi;
  // console.log(data, "profile")


  const NavMenu = (
    <>
      <div className='flex shrink-0 items-center mt-8 w-auto justify-center'>
        <img className='h-7 w-40' src={Logo} alt='TechRevolve' />
      </div>
      <nav className='flex flex-1 flex-col'>
        <p className='text-[#11111180] text-xs font-normal -tracking-[0.44] mt-10 mb-4'> MENU </p>
        <ul className='flex flex-1 flex-col gap-y-7'>
          <li>
            <ul className='flex flex-col gap-y-2'>
              {navigation.map(({ href, name, defaultIcon, activeIcon }) => {
                const isActive = window.location.pathname.includes(href);
                return (
                  <Link
                    to={href}
                    key={name}
                    className={` flex gap-3 items-center py-3 px-4 ${isActive ? 'bg-neat-light rounded-lg lg:shadow-custom' : ''
                      }`}>
                    <img
                      src={isActive ? `/images/${activeIcon}` : `/images/${defaultIcon}`}
                      alt=''
                    />
                    <p className={`text-sm ${isActive ? ' font-medium' : 'text-dark font-normal'}`}>
                      {name}
                    </p>
                    {isActive && <div className="absolute bg-primary rounded-sm w-[5px] h-6 left-[12px] mx-2.5 " />}
                  </Link>
                );
              })}
            </ul>
          </li>
        </ul>
        <div className='flex flex-row justify-start -ml-2.5'>
          <img src={Profile} className='h-10' alt="Side Bar Profile" />
          <p className='-ml-1 text-sm h-0 capitalize'>{first_name}</p>
        </div>
      </nav>
    </>
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as='div' className='relative z-50 lg:hidden' onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'>
              <div className='fixed inset-0 bg-gray-900/80' />
            </Transition.Child>

            <div className='fixed inset-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'>
                <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'>
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button
                        type='button'
                        className='-m-2.5 p-2.5'
                        onClick={() => setSidebarOpen(false)}>
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* SIDEBAR COMPONENT */}
                  <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4'>
                    {NavMenu}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'>
          <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white border-r border-[#41423E1A] px-6 pb-4'>
            {NavMenu}
          </div>
        </div>

        <div className='lg:pl-72'>
          <div className='sticky top-0 z-40 flex shrink-0 items-center gap-x-4 bg-white px-4 sm:gap-x-6 sm:px-6 lg:px-6 lg:py-4 py-4 border-b-[1px]'>
            <button
              type='button'
              className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
              onClick={() => setSidebarOpen(true)}>
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon className='h-6 w-6' aria-hidden='true' />
            </button>

            <div className='flex  gap-x-4 justify-between items-center w-full'>
              <h3 className='text-sm lg:text-xl font-semibold'>{activeRouteName}</h3>
              <div className='flex gap-x-16'>
                {/* <div className='hidden md:block'><Textfield className='min-w-[382px]' id="" searchField placeholder='Search' /></div> */}
                <div className='hidden md:block'>
                  <input type="text" placeholder='Search' className='class="block w-full bg-[#41423E1A] rounded-[44px] border-0 py-2 ring-1 placeholder:text-[#969595] placeholder:font-normal focus:ring-1 focus:outline-none text-sm leading-6 undefined disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed disabled:hover:ring-gray-300 ring-gray-300 pl-10 pr-3 min-w-[382px]' />
                </div>
                <ProfileDropDown />
              </div>
            </div>
          </div>

          <div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
