import AuthLayout from "components/layouts/AuthLayout";
import { Button } from "components/shared/Button";
import { useNavigate } from "react-router-dom";

const RegisterTakeTestAuth = () => {
  const navigate = useNavigate()

  const handleContinue = () => {
    navigate('/register')
  };

  return (
    <AuthLayout>
      <div className="">
        <div className="mt-32 text-left md:w-[560px] mb-10">
          <h1 className="text-4xl text-dark font-medium mb-7">Register to take tests.</h1>
          <p className="text-lg text-dark/80 font-light mb-4">The registration process usually requires approximately 15 minutes. You can resume and complete the process at your convenience by revisiting the TechRevolve platform.</p>
          <p className="text-lg text-dark/80 font-light">Please be aware that in order to participate, you must be at least 18 years of age and you need a device connected to the internet.</p>
        </div>
        <Button type="button" label='Continue' onClick={handleContinue} />
      </div>
    </AuthLayout>
  )
}
export default RegisterTakeTestAuth