import ArrowRight from 'assets/arrow-right-icon.svg'
import { getColor, getTextColor } from 'utils/constants';
import { ClockIcon } from 'assets/svg/SvgIcons';

interface Test {
  id: number;
  duration: number;
  reward?: number;
  title?: string;
  heading?: string;
  description?: string;
  needsEligibility?: boolean;
}


interface TestCardProps {
  test: Test;
  onClick: () => void;
}


export const TestCard: React.FC<TestCardProps> = ({ test, onClick }) => {
  const color = getColor(test.duration);
  const textColor = getTextColor(test.duration);
  return (
    <div className="max-w-[354.67px] bg-white py-6 px-4 border-[0.5px] border-black/10 rounded" onClick={onClick}>
      <div className="flex mb-5 justify-between">
        <div className="flex rounded-[10px] justify-center items-center py-[1px] px-2 gap-x-[2px]" style={{ backgroundColor: color }}>
          <span> <ClockIcon color={textColor} /></span>
          <p className=" text-[12px]" style={{ color: textColor }}>{test.duration} minutes</p>
        </div>
        <div>
          <h3 className=" font-bold text-[#181B01]">$ 50</h3>
        </div>
      </div>
      <div className="mb-2">
        <h2 className="text-[23px] w-[250px] font-medium leading-[30px]">Toople sign up process survey</h2>
      </div>
      <div className="flex justify-between ">
        <p className="text-[#1E1E1E]/40 text-xs font-normal">Effects of failed sign up processes</p>
        <img src={ArrowRight} alt="" />
      </div>
    </div>
  )
}