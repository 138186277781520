// import { logoutApi } from 'services/api/authApi';

export const handleLogout = async () => {
  try {

    localStorage.removeItem('techrevolve_user');
    localStorage.removeItem('techrevolve_email');
    localStorage?.clear();
    window.location.href = '/';
  } catch (error) { }
};
