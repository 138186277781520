import { string, object } from 'yup';
import { emailValidation, passwordValidation, defaultValidation } from '.';

export const loginFormValidationSchema = object().shape({
  email: emailValidation(),
  password: string().required('Password is required'),
});

export const forgotPasswordFormValidationSchema = object().shape({
  email: emailValidation(),
});

export const resetPasswordFormValidationSchema = object().shape({
  password: passwordValidation(),
  confirm_password: string()
    .test(
      'password-match',
      'Password and Confirm Password must match',
      function (value?: string) {
        return this.parent.password === value;
      }
    )
    .required('Confirm Password is required'),
});

export const registerFormValidationSchema = object().shape({
  email: emailValidation(),
  password: string().required('Password is required'),
});

export const completeFormValidationSchema = object().shape({
  first_name: defaultValidation('First Name'),
  last_name: defaultValidation('Last Name'),
  username: defaultValidation('User Name'),
});

export const connectBankFormValidationSchema = object().shape({
  bank_code: defaultValidation("Bank "),
  account_number: string().required("Account Number is required").matches(/^[0-9]{10}$/, "Account Number must be 10 digits"),
});
