import { useAuth } from 'hooks';
import Home from 'pages/(home)';
import Login from 'pages/(auth)/login';
import Help from 'pages/(dashboard)/help';
import Test from 'pages/(dashboard)/test';
import Register from 'pages/(auth)/register';
import Profile from 'pages/(dashboard)/profile';
import { Routes, Route, Navigate } from 'react-router-dom';
import Messages from 'pages/(dashboard)/messages';
import Survey from 'modules/test/take-test/survey';
import Dashboard from 'pages/(dashboard)/dashboard';
import VerifyEmail from 'pages/(auth)/verify-email';
// import DashboardLayout from 'pages/(dashboard)/layout';
import DashboardLayout from 'components/layouts/DashboardLayouts'
import TakeTest from 'pages/(dashboard)/test/take-test';
import ResetPassword from 'pages/(auth)/reset-password';
import ForgotPassword from 'pages/(auth)/forgot-password';
import VerifyPassword from "pages/(auth)/verify-password";
// import AccountTypeSelect from 'pages/(auth)/account-type';
import RegisterTakeTest from 'pages/(auth)/register-take-test';
import CompleteRegistration from 'pages/(auth)/complete-register';
import Eligibility from 'modules/test/take-test/survey/eligibility';

const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated } = useAuth();
  console.log(isAuthenticated, 'isAuthenticated')
  return (
    isAuthenticated ? (
      <DashboardLayout>{children}</DashboardLayout>
    ) : (
      <Navigate to="/" />
    )

  );
};

const AppRoutes = () => {
  const routes = [
    // {
    //   path: '/register/account-type',
    //   element: <AccountTypeSelect />,
    //   isRequireAuth: false,
    // },
    {
      path: '/register/start',
      element: <RegisterTakeTest />,
      isRequireAuth: false,
    },
    {
      path: '/register',
      element: <Register />,
      isRequireAuth: false,
    },
    {
      path: '/register/verify-email',
      element: <VerifyEmail />,
      isRequireAuth: false,
    },
    {
      path: '/register/complete',
      element: <CompleteRegistration />,
      isRequireAuth: false,
    },
    {
      path: '/',
      element: <Login />,
      isRequireAuth: false,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
      isRequireAuth: false,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
      isRequireAuth: false,
    },
    {
      path: '/verify-password',
      element: <VerifyPassword />,
      isRequireAuth: false,
    },
    {
      path: '/dashboard',
      element: <Dashboard />,
      isRequireAuth: true,
    },
    {
      path: '/test-history',
      element: <Test />,
      isRequireAuth: true,
    },
    {
      path: '/test',
      element: <TakeTest />,
      isRequireAuth: true,
    },
    {
      path: '/test/survey',
      element: <Survey />,
      isRequireAuth: false,
    },
    {
      path: '/test/eligibility',
      element: <Eligibility />,
      isRequireAuth: false,
    },
    {
      path: '/help',
      element: <Help />,
      isRequireAuth: true,
    },
    {
      path: '/messages',
      element: <Messages />,
      isRequireAuth: true,
    },
    {
      path: '/profile',
      element: <Profile />,
      isRequireAuth: true,
    },
    {
      path: '*',
      element: <h2>NOT FOUND</h2>,
      isRequireAuth: true,
    },
  ];

  return (
    <Routes>
      {routes.map(({ path, element, isRequireAuth }) =>
        isRequireAuth ? (
          <Route key={path} path={path} element={<ProtectedRoute>{element}</ProtectedRoute>} />
        ) : (
          <Route key={path} path={path} element={element} />
        )
      )}
    </Routes>
  );
};

export default AppRoutes;
