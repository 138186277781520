import { LayoutProps } from 'types';
import map from 'assets/map-icon.svg'
import Logo from 'assets/techrevolve.svg'

const AuthLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="relative min-h-screen flex ">
      <div className="sticky top-0 hidden w-[380px] h-screen shrink-0 bg-primary lg:flex lg:justify-between lg:flex-col lg:items-center">
        <div className='pt-[53px]'>
          <img className='h-7 w-56' src={Logo} alt='TechRevolve' />
        </div>

        <img src={map} alt="auth map" />
      </div>

      <div className=" grow w-full max-w-[720px] h-full mx-auto  gap-3 p-4 md:p-6">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
