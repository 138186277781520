export type TestDuration = {
  min: number;
  max: number;
  color: string;
  textColor: string;
};

export const testDurations: TestDuration[] = [
  { min: 1, max: 5, color: '#F8EEE5', textColor: '#F2994A' },
  { min: 6, max: 10, color: '#E7F6EC', textColor: '#099137' },
  { min: 11, max: 15, color: '#FDEDED', textColor: '#EB5757' },
  // Add more ranges if needed
];

export const getColor = (duration: number): string => {
  const durationRange = testDurations.find(range => duration >= range.min && duration <= range.max);
  return durationRange ? durationRange.color : '#000';
};

export const getTextColor = (duration: number): string => {
  const durationRange = testDurations.find(range => duration >= range.min && duration <= range.max);
  return durationRange ? durationRange.textColor : '#fff';
};
