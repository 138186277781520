import { instance } from 'services/axios/axiosInstance';
import {
  CompleteRegisterFormPayload,
  ForgotPasswordFormPayload,
  LoginFormPayload,
  RegisterFormPayload,
  ResetPasswordFormPayload,
  VerifyAccountFormPayload,
} from 'types/auth-types';
import { apiRequestParams, UnknownObjectType } from '.';
import { notification } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query'

const formatError = (error: any) => {

  if (typeof error === 'string') {
    notification.error({ message: error });
    if (error.toLocaleLowerCase().includes('exists')) {
      window.location.href = '/'
    }
  } else {
    notification.error(Object.values(error as UnknownObjectType)[0]);
  }
};

// export const registerApi = async (payload: RegisterFormPayload) => {
//   const { data } = await instance.post(`/accounts/create`, payload, {
//   });
//   return data;
// };

export const useSignupApi = ({ onError, onSuccess }: apiRequestParams) => {
  const { mutate: handleRegister, isPending: isLoading } = useMutation({
    mutationFn: async (requestPayload: RegisterFormPayload) => {
      const { data } = await instance.post(
        `/accounts/create
      `,
        requestPayload
      );
      return data;
    },
    onSuccess,
    onError: formatError,
  });

  return { handleRegister, isLoading };
};

export const completeApi = async (payload: CompleteRegisterFormPayload) => {
  const storedUser = localStorage.getItem('techrevolve__user');
  if (!storedUser) {
    throw new Error("User not found in localStorage.");
  }

  const user = JSON.parse(storedUser);
  const userId = user.id;

  const { data } = await instance.put(`/accounts/${userId}`, payload);
  return data;
};

export const loginApi = async (payload: LoginFormPayload) => {
  const { data } = await instance.post(`/accounts/login`, payload, {
  });
  return data;
};

export const useCompleteApi = (accountId: number, payload: CompleteRegisterFormPayload) => {
  const getComplete = async () => {
    const { data } = await instance.post(`/accounts/${accountId}`, payload);
    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['get-complete', accountId, payload], // Use accountId and payload in the queryKey for caching
    queryFn: getComplete, // No need to pass arguments directly here, the closure handles it
  });

  return { data, isLoading };
};


export const forgotPasswordApi = async (payload: ForgotPasswordFormPayload) => {
  const { data } = await instance.post(`/accounts/password_reset`, payload);
  return data;
};

export const verifyResetPasswordTokenApi = async (payload: VerifyAccountFormPayload) => {
  const { data } = await instance.post(
    `/accounts/account_verification`,
    payload,

  );
  return data;
};
export const useResendVerificationEmailApi = ({ onSuccess }: apiRequestParams) => {
  const { mutate: resendVerificationEmail, isPending: isLoadingVerification } = useMutation({
    mutationFn: async (email: string) => {
      const { data } = await instance.post(
        `/accounts/resend_account_verification
      `,
        { email }
      );
      return data;
    },
    onSuccess,
  });

  return {
    resendVerificationEmail,
    isLoadingVerification,
  };
};
export const verifyResetAccountTokenApi = async (payload: ResetPasswordFormPayload) => {
  const { data } = await instance.post(
    `/accounts/resend_account_verification`,
    payload,

  );
  return data;
};

export const logoutApi = async () => {
  const response = await instance.post('/accounts/logout');
  return response.data
};