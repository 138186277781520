import { motion, AnimatePresence } from 'framer-motion';

interface AccordionProps {
  title: string;
  content: string | React.ReactNode;
  isActive: boolean;
  handleToggleAccordion: (val: any) => void;
  variant?: 'primary' | 'secondary';
  className?: string;
  [key: string]: any;
}

export const Accordion: React.FC<AccordionProps> = ({
  title,
  content,
  isActive,
  className,
  variant = 'primary',
  handleToggleAccordion,
  rest,
}) => {
  const isPrimaryVariant = variant === 'primary';

  return (
    <div
      className={className}
      {...rest}>
      <div
        className='flex justify-between gap-2 lg:gap-4  items-center cursor-pointer'
        onClick={handleToggleAccordion}>
        <p
          className={` font-semibold text-[#1C1F00]`}>
          {title}
        </p>
        <div className='min-h-[40px] h-[40px] min-w-[40px]'>
          <div className='flex justify-center h-full w-full items-center  font-bold'>
            {isActive ? (
              <svg
                width="18"
                height="2"
                viewBox="0 0 18 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M18 1C18 1.19891 17.921 1.38968 17.7803 1.53033C17.6397 1.67098 17.4489 1.75 17.25 1.75H0.75C0.551088 1.75 0.360322 1.67098 0.21967 1.53033C0.0790178 1.38968 0 1.19891 0 1C0 0.801088 0.0790178 0.610322 0.21967 0.46967C0.360322 0.329018 0.551088 0.25 0.75 0.25H17.25C17.4489 0.25 17.6397 0.329018 17.7803 0.46967C17.921 0.610322 18 0.801088 18 1Z" fill="black" />
              </svg>

            ) : (
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12.25V12.75C19 13.0261 18.7762 13.25 18.5 13.25H12.75V19C12.75 19.2761 12.5261 19.5 12.25 19.5H11.75C11.4739 19.5 11.25 19.2761 11.25 19V13.25H5.5C5.22386 13.25 5 13.0261 5 12.75V12.25C5 11.9739 5.22386 11.75 5.5 11.75H11.25V6C11.25 5.72385 11.4739 5.5 11.75 5.5H12.25C12.5261 5.5 12.75 5.72385 12.75 6V11.75H18.5C18.7762 11.75 19 11.9739 19 12.25Z" fill="black" />
              </svg>

            )}
          </div>
        </div>
      </div>
      <AnimatePresence initial={false}>
        {isActive && (
          <motion.section
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.3 }}>
            <div className='pb-3 pr-10 xl:pr-20 leading-6 max-h-[400px] font-light'>
              {content}
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};
